import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import Group from '~/prop-types/groupPropType';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DEVICES_PATH } from '~/features/base/constants/routes';
import {
    ENTITY_CONTROL_DEVICE,
    ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_ASSIGNED,
} from '~/features/base/constants/entities';

import { followRoute } from '~/features/base/actions/ui/routeActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import { exportDevices } from '~/features/devices/actions/exportActions';
import {
    fetchFilteredControlDevices,
} from '~/features/devices/actions/controlDeviceActions';
import { clearPage } from '~/features/higherorder/actions/paginationActions';

import { pathnameSelector, searchSelector } from '~/features/base/selectors/locationSelectors';
import {
    canReadControlDevicesSelector, controlDevicePageItemsSelector, controlDeviceTotalElementsSelector,
    devicesAssignedLoadingSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import { groupSelector } from '~/features/groups/selectors/groupSelectors';
import {
    devicesAssignedPageNumberSelector,
    devicesAssignedPageCountSelector,
} from '~/features/groups/selectors/groupDevicesAssignedSelectors';

import { parseQuery } from '~/features/base/utils/query';

import GroupDevicesAssigned from '~/features/groups/components/GroupDevicesAssigned';

import uid from '~/features/base/utils/uid';

const FETCH_DEVICES_AMOUNT_INCREMENT = 50;

export class GroupDevicesAssignedContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();

        const { search } = this.props;
        const parsedQuery = parseQuery(search);

        this.registerDataInterest(parsedQuery);
        this.props.clearPage();
        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
        this.onLoadMore = this.onLoadMore.bind(this);
    }

    onShowItem({ serialNumber }) {
        const route = `/${DEVICES_PATH}/${serialNumber}`;
        this.props.followRoute({ route });
    }

    onShowPage(page) {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                page,
            },
        });
    }

    onExportDevices(format = 'csv') {
        // get criteria from props
        const { group, totalElements } = this.props;
        const options = {
            format,
            totalElements,
            searchCriteria: {
                whitelistingInfo: {
                    vehicleGroupWhitelist: [group.name],
                },
            },
        };
        this.props.exportDevices(options);
    }

    onLoadMore() {
        const { pathname, search } = this.props;
        const query = parseQuery(search);
        const queryAmount = Number(query.amount ? query.amount : FETCH_DEVICES_AMOUNT_INCREMENT)
            + FETCH_DEVICES_AMOUNT_INCREMENT;
        this.props.followRoute({
            route: pathname, query: {
                ...query,
                amount: queryAmount,
            },
        });
    }

    render() {
        return (
            <GroupDevicesAssigned {...this.props}
                                  fetchDevicesTotalAmountIncrement={FETCH_DEVICES_AMOUNT_INCREMENT}
                                  onShowPage={(page) => this.onShowPage(page)}
                                  onShowItem={(hwSerial) => this.onShowItem(hwSerial)}
                                  onExportDevices={(format) => this.onExportDevices(format)}
                                  onLoadMore={this.onLoadMore}/>
        );
    }

    registerDataInterest(parsedQuery) {
        const { groupName } = this.props;
        const { page, amount } = parsedQuery;
        this.props.registerDataInterest(this.name, [
            fetchFilteredControlDevices
            ({
                searchCriteria: { whitelistingInfo: { vehicleGroupWhitelist: [groupName] } },
                page: (page && page > 0) ? (page - 1) : 0,
                size: amount ? amount : FETCH_DEVICES_AMOUNT_INCREMENT,
            }),
        ]);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }

    componentWillReceiveProps(nextProps) {
        const { search } = nextProps;
        if (search !== this.props.search) {
            const parsedQuery = parseQuery(search);
            this.registerDataInterest(parsedQuery);
            nextProps.triggerDataFetcher();
        }
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        pathname: pathnameSelector(state),
        search: searchSelector(state),
        canReadControlDevices: canReadControlDevicesSelector(state),
        devicesLoading: devicesAssignedLoadingSelector(state),
        pageNumber: devicesAssignedPageNumberSelector(state),
        pageCount: devicesAssignedPageCountSelector(state),
        totalElements: controlDeviceTotalElementsSelector(state, ownProps),
        group: groupSelector(state, ownProps),
        groupDevicesAssigned: controlDevicePageItemsSelector(state),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        followRoute: (options) => {
            dispatch(followRoute(options));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
        clearPage: () => {
            dispatch(clearPage({
                entityName: ENTITY_CONTROL_DEVICE,
                scope: ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_ASSIGNED,
            }));
        },
        exportDevices: (options) => {
            dispatch(exportDevices(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDevicesAssignedContainer);

GroupDevicesAssignedContainer.defaultProps = {
    // props
    pathname: '',
    search: '',
    canReadControlDevices: false,
    groupName: '',
    group: {},
    pageNumber: 0,
    pageCount: 0,
    totalElements: 0,
    groupDevicesAssigned: [],
    // functions
    followRoute: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
    exportDevices: noop,
    clearPage: noop,
};

GroupDevicesAssignedContainer.propTypes = {
    // props
    pathname: PropTypes.string,
    search: PropTypes.string,
    canReadControlDevices: PropTypes.bool,
    devicesLoading: PropTypes.bool,
    groupName: PropTypes.string,
    group: PropTypes.instanceOf(Group),
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    totalCount: PropTypes.number,
    groupDevicesAssigned: PropTypes.array,
    // functions
    followRoute: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
    exportDevices: PropTypes.func,
    clearPage: PropTypes.func,
};
