import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
    DELIVERABLES_PATH,
} from '~/features/base/constants/routes';
import { FETCH_DELIVERABLES_AMOUNT_INCREMENT } from '~/features/deliverables/constants/deliverablesParameters';

export class DeliverableTypeTabs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const navigation = this.renderNavigation();
        return (
            <div className='file-details-tabs row'>
                <div className='col-md-12'>
                    {navigation}
                </div>
            </div>
        );
    }

    renderNavigationItem(basePath, view, label, currentView) {
        const className = {
            active: currentView === view,
        };
        return (
            <li key={view} className={classNames(className)}>
                <Link to={`${basePath}/${view}?page=0&size=${FETCH_DELIVERABLES_AMOUNT_INCREMENT}`}><FormattedMessage
                    id={label}/></Link>
            </li>
        );
    }

    renderNavigation() {
        const { basePath, view } = this.props;
        return (
            <ul className='nav nav-tabs user-select-none'>
                {this.renderNavigationItem(basePath, DELIVERABLES_FILES_PATH, 'intl-msg:files', view)}
                {this.renderNavigationItem(basePath, DELIVERABLES_CM4G_PATH, 'intl-msg:cm4g', view)}
                {this.renderNavigationItem(basePath, DELIVERABLES_BUNDLES_PATH, 'intl-msg:bundle', view)}
            </ul>
        );
    }
}

export default DeliverableTypeTabs;

DeliverableTypeTabs.defaultProps = {
    // props
    basePath: '',
    view: DELIVERABLES_PATH,
};

DeliverableTypeTabs.propTypes = {
    // props
    basePath: PropTypes.string,
    view: PropTypes.string,
};
