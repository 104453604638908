export const iconsByVehicleVariant = {
    // Traton relevant
    core: 'rioglyph rioglyph-truck',
    sesamm7: 'rioglyph rioglyph-truck',
    // legalcy
    e4c: 'rioglyph rioglyph-bus',
    nhmr: 'rioglyph rioglyph-truck',
    tgx: 'rioglyph rioglyph-truck',
    ican: 'rioglyph rioglyph-truck',
    vw: 'rioglyph rioglyph-truck',
    fms: 'rioglyph rioglyph-van',
    other: 'rioglyph rioglyph-warning-sign',
    delivery: 'rioglyph rioglyph-delivery',
};
