import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { groupReferencesRowDescriptors } from '~/features/groups/constants/groupRowDescriptors';

import { fetchReferencesOfGroup } from '~/features/groups/actions/groupActions';

import GroupReferences from '~/features/groups/components/GroupReferences';
import { groupReferencesSelector } from '~/features/groups/selectors/groupReferencesSelectors';

export class GroupReferencesContainer extends PureComponent {

    constructor(props) {
        super(props);
        props.fetchReferencesOfGroup(props.groupName);
    }

    render() {
        const { references } = this.props;
        return (
            <GroupReferences references={references} rowDescriptors={groupReferencesRowDescriptors}/>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        references: groupReferencesSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchReferencesOfGroup: (groupName) => {
            dispatch(fetchReferencesOfGroup(groupName));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupReferencesContainer);

GroupReferencesContainer.defaultProps = {
    // props
    groupName: '',
    references: {},
    // functions
    fetchReferencesOfGroup: noop,
};

GroupReferencesContainer.propTypes = {
    // props
    groupName: PropTypes.string,
    references: PropTypes.object,
    // functions
    fetchReferencesOfGroup: PropTypes.func,
};
