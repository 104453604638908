export const ENTITY_SERIAL_NUMBER = 'serialNumber';

export const ENTITY_CONTROL_DEVICE = 'controlDevice';
export const ENTITY_CONTROL_DEVICE_SCOPE_DEVICES = 'devices';
export const ENTITY_VEHICLES = 'vehicles';
export const ENTITY_VEHICLES_SCOPE_DEVICES = 'vehicles';
export const ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_ASSIGNED = 'devicesAssigned';
export const ENTITY_CONTROL_DEVICE_SCOPE_DISTRO_WHITELIST = 'distroWhitelist';
export const ENTITY_CONTROL_DEVICE_SCOPE_PACKAGE_DELIVERABLE_WHITELIST = 'packageDeliverableWhitelist';
export const ENTITY_CONTROL_DEVICE_SCOPE_FILE_DELIVERABLE_WHITELIST = 'fileDeliverableWhitelist';
export const ENTITY_CONTROL_DEVICE_SCOPE_DEVICES_WITH_LOGS = 'devicesWithLogs';

export const ENTITY_CONTROL_DEVICE_INFO = 'controlDeviceInfo';
export const ENTITY_VEHICLE_INFO = 'vehicleInfo';
export const ENTITY_CONTROL_DEVICE_REFERENCED_DELIVERABLES = 'controlDeviceReferencedDeliverables';
export const ENTITY_CONTROL_DEVICE_ELIGIBLE_PACKAGE_VERSIONS = 'controlDeviceEligiblePackageVersions';
export const ENTITY_CONTROL_DEVICE_ELIGIBLE_CM4G_VERSIONS = 'controlDeviceEligibleCM4GVersions';
export const ENTITY_CONTROL_DEVICE_ELIGIBLE_FILE_VERSIONS = 'controlDeviceEligibleFileVersions';
export const ENTITY_CONTROL_DEVICE_ELIGIBLE_BUNDLE_VERSIONS = 'controlDeviceEligibleBundleVersions';

export const ENTITY_DEVICE_LOGS = 'deviceLogs';
export const ENTITY_CONTROL_DEVICE_OPERATION = 'controlDeviceOperation';
export const ENTITY_CONTROL_DEVICE_ROLLOUT_STATES = 'controlDeviceRolloutStates';
export const ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS = 'controlDeviceSystemStateNotifications';
export const ENTITY_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATIONS_SCOPE_DEVICE = 'device';

export const ENTITY_GROUP = 'group';
export const ENTITY_DEVICE_GROUP = 'deviceGroup';
export const ENTITY_GROUP_REFERENCES = 'groupReferences';

export const ENTITY_DISTRO = 'distro';
export const ENTITY_DISTRO_SCOPE_LATEST = 'latestDistro';
export const ENTITY_DISTRO_HISTORY = 'distroHistory';
export const ENTITY_DELIVERABLE_HISTORY = 'deliverableHistory';
export const ENTITY_LIVE_DISTROS = 'liveDistros';
export const ENTITY_LIVE_APPS = 'liveApps';
export const ENTITY_LIVE_CM4GS = 'liveCM4Gs';
export const ENTITY_LIVE_BUNDLES = 'liveBundles';
export const ENTITY_BASE_SW = 'baseSwVersion';
export const ENTITY_BASE_SW_VERSIONS = 'baseSwVersions';
export const ENTITY_BASE_SW_SCOPE_ALL = 'allBaseSwVersions';
export const ENTITY_BASE_SW_SCOPE_EDITABLE = 'editableBaseSwVersions';
export const ENTITY_HW_VARIANTS_ALL = 'allHardwareVariants';
export const ENTITY_DEVICE_STATUS_NAMES_ALL = 'allDeviceStatusNames';
export const ENTITY_DEVICE_STATUS_TYPES_ALL = 'allDeviceStatusTypes';
export const ENTITY_VEHICLE_SPARE_PART_NUMBERS_ALL = 'allVehicleSparePartNumbers';
export const ENTITY_VEHICLE_VARIANTS_ALL = 'allVehicleVariants';
export const ENTITY_DEVICE_COUNTS_BY_COUNTRIES = 'deviceCountsCountries';
export const ENTITY_DEVICE_COUNTS_BY_VEHICLES = 'deviceCountsVehicles';

export const ENTITY_CM4G = 'cm4g'
export const ENTITY_CM4G_DELIVERABLE = 'cm4gDeliverable'
export const ENTITY_PACKAGE = 'package';
export const ENTITY_PACKAGE_DELIVERABLE = 'packageDeliverable';

export const ENTITY_FILE = 'file';
export const ENTITY_FILE_DELIVERABLE = 'fileDeliverable';

export const ENTITY_ARTIFACT = 'artifact';
export const ENTITY_ARTIFACT_FILES = 'artifactFiles';
export const ENTITY_ARTIFACT_USER_INFO = 'artifactUserInfo';
export const ENTITY_ARTIFACT_SCOPE_DEVICES = 'artifacts';

export const ENTITY_DELIVERABLE = 'deliverable';
export const ENTITY_DELIVERABLE_FILES = 'deliverableFiles';
export const ENTITY_DELIVERABLE_ID = 'deliverableId';
export const ENTITY_DELIVERABLE_SCOPE_DEVICES = 'deliverables';
export const ENTITY_DELIVERABLE_IDS_SCOPE_DEVICES = 'deliverableIds';
export const ENTITY_DELIVERABLES_USER_INFO = 'deliverableUserInfo';
export const ENTITY_DELIVERABLES_CONTEXT_INFO = 'deliverableContextInfo';
export const ENTITY_DELIVERABLES_VERSIONS = 'deliverableVersions';

export const ENTITY_DEFAULT_NETWORK_CODE_LIST = 'defaultNetworkCodeList';

export const ENTITY_DEVICE_ACTION = 'deviceAction';

export const ENTITY_SYS_PARAM_DEFINITION = 'sysParamDefinition';
export const ENTITY_SYS_PARAM_DEFAULT_VALUE = 'sysParamDefaultValue';
export const ENTITY_SYS_PARAM_SYSTEM_VALUE = 'sysParamSystemValue';
export const ENTITY_SYS_PARAM_DEVICE_VALUE = 'sysParamDeviceValue';

export const ENTITY_USER = 'user';

export const IDENTIFIER_CONTROL_DEVICE = 'serialNumber';
export const IDENTIFIER_VEHICLE = 'vin';
export const IDENTIFIER_ARTIFACT = 'name';
export const IDENTIFIER_ARTIFACT_FILE = 'artifactId';

export const IDENTIFIER_DELIVERABLE = 'deliverableId';
export const IDENTIFIER_CONTROL_DEVICE_OPERATION = 'operationId';
export const IDENTIFIER_CONTROL_DEVICE_ROLLOUT_STATE = 'id';
export const IDENTIFIER_CONTROL_DEVICE_SYSTEM_STATE_NOTIFICATION = 'id';
export const IDENTIFIER_GROUP = 'name';
export const IDENTIFIER_GROUP_REFERENCES = 'group';
export const IDENTIFIER_DISTRO = 'shortDistroVersion';
export const IDENTIFIER_BASE_SW = 'shortBaseSwVersion';
export const IDENTIFIER_PACKAGE = 'packageId';
export const IDENTIFIER_PACKAGE_DELIVERABLE = 'packageIdAndVersion';
export const IDENTIFIER_GENERIC_DELIVERABLE = 'deliverableIdAndVersion';
export const IDENTIFIER_FILE = 'fileId';
export const IDENTIFIER_FILE_DELIVERABLE = 'fileIdAndVersion';
export const IDENTIFIER_SYS_PARAM = 'accessKey';
export const IDENTIFIER_DEVICE_ACTION = 'hwSerial';
export const IDENTIFIER_DISTRO_HISTORY = 'historyId';

export const ENTITY_UPDATE_EVENT = 'updateEvent';
export const ENTITY_UPDATE_EVENT_SCOPE_ALL = 'all';
export const ENTITY_UPDATE_EVENT_SCOPE_DEVICE = 'device';
export const ENTITY_UPDATE_EVENT_SCOPE_DISTRO = 'distro';
export const ENTITY_UPDATE_EVENT_SCOPE_PACKAGE = 'package';
export const ENTITY_UPDATE_EVENT_SCOPE_FILE = 'file';
export const ENTITY_UPDATE_EVENT_REPORT = 'updateEventReport';

export const IDENTIFIER_UPDATE_EVENT = 'id';

export const ENTITY_REPORTS_BILLING = 'reportsBilling';
export const ENTITY_REPORTS_BILLING_DETAILS = 'reportsBillingDetails';
export const ENTITY_REPORTS_BILLING_CHARTS_INITIAL_DATA = 'chartsInitialData';
