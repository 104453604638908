export const FETCH_PACKAGE_DELIVERABLE_COUNT = 'FETCH_PACKAGE_DELIVERABLE_COUNT';
export const FETCH_CM4G_DELIVERABLE_COUNT = 'FETCH_CM4G_DELIVERABLE_COUNT';
export const FETCH_PACKAGE_DELIVERABLE = 'FETCH_PACKAGE_DELIVERABLE';
export const FETCH_PACKAGE_DELIVERABLE_VERSIONS = 'FETCH_PACKAGE_DELIVERABLE_VERSIONS';

export const fetchPackageDeliverableCount = () => {
    return {
        type: FETCH_PACKAGE_DELIVERABLE_COUNT,
    };
};

export const fetchCm4gDeliverableCount = () => {
    return {
        type: FETCH_CM4G_DELIVERABLE_COUNT,
    };
};

export const fetchPackageDeliverable = ({ packageId, shortPackageVersion }) => {
    return {
        type: FETCH_PACKAGE_DELIVERABLE,
        payload: {
            packageId,
            shortPackageVersion,
        },
    };
};

export const fetchPackageDeliverableVersions = (packageId) => {
    return {
        type: FETCH_PACKAGE_DELIVERABLE_VERSIONS,
        payload: {
            packageId,
        },
    };
};
