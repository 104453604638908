import {ENTITY_FILE_DELIVERABLE, ENTITY_PACKAGE_DELIVERABLE} from '~/features/base/constants/entities';

import {
    aggregationStoreSelector,
    entityStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';

import { toFileIdAndVersion } from '~/features/deliverables/features/files/transforms/parseFileDeliverables';
import get from "lodash/fp/get";

// Selectors
const fileDeliverableEntitiesSelector = state => entityStoreSelector(state)[ENTITY_FILE_DELIVERABLE];
const fileDeliverableAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_FILE_DELIVERABLE];

 // Exports
export const fileDeliverableSelector = (state, { fileId, shortFileVersion }) => {
    return fileDeliverableEntitiesSelector(state)[toFileIdAndVersion(fileId, shortFileVersion)];
};
export const fileDeliverableCountSelector = state => get('totalCount', fileDeliverableAggregationsSelector(state));
