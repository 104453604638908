import flow from 'lodash/fp/flow';
import pick from 'lodash/fp/pick';

import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { FETCH_FILTERED_VEHICLES } from '~/features/oldVehicles/actions/vehicleActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';
import { ENTITY_VEHICLES } from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { mergeEntities } from '~/features/higherorder/actions/entityActions';
import { mergePage } from '~/features/higherorder/actions/paginationActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';
import { stringifyToQuery } from '~/features/base/utils/query';

import { doHandleErrorSaga, getHTTP, postHTTP, putHTTP } from '~/features/base/sagas/sagaUtil';
import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';
import { parseVehicles } from '~/features/oldVehicles/transforms/parseVehicles';

export function* getVehicleURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v2/client/vehicle`;
}

export function* receiveFilteredVehiclesSaga(action) {
    try {
        const url = yield call(getVehicleURL);
        const query = flow(pick(['page', 'size']), stringifyToQuery)(action);
        if (action.searchCriteria.distroVersion && (!action.searchCriteria.distroVersion.year
            || !action.searchCriteria.distroVersion.week
            || !action.searchCriteria.distroVersion.number)) {
            action.searchCriteria.distroVersion = {
                year: 1,
                week: 1,
                number: 1,
            };
        }
        const { content, ...pagination } = yield call(postHTTP, `${url}/search/default-view?${query}`,
            JSON.stringify(action.searchCriteria));
        const parsedResponse = { content: parseVehicles(content), ...pagination };
        return parsedResponse;
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
}

export function* mergeFilteredVehiclesSaga(response, scope) {
    yield put(mergeEntities(response.content, { entityName: ENTITY_VEHICLES }));
    yield put(mergePage({ entityName: ENTITY_VEHICLES, scope }, response));
}

export function* doFetchFilteredVehiclesSaga(action) {
    yield put(startLoading(ENTITY_VEHICLES, action.scope));
    const response = yield call(receiveFilteredVehiclesSaga, action);
    if (response) {
        yield call(mergeFilteredVehiclesSaga, response, action.scope);
    }
    yield put(endLoading(ENTITY_VEHICLES, action.scope));
    yield put(finishedAction(action.type));
}

export function* fetchFilteredVehiclesSaga() {
    yield takeLatest(FETCH_FILTERED_VEHICLES, doFetchFilteredVehiclesSaga);
}
