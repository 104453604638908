import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import DeviceListTableToolbar from '~/features/devices/components/DeviceListTableToolbar';
import DeviceListTable from '~/features/devices/components/DeviceListTable';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import isEmpty from 'lodash/fp/isEmpty';

const columns = ['serialNumber', 'vin'];

class DeliverableWhitelist extends PureComponent {
    render() {
        const { canReadControlDevices } = this.props;
        if (!canReadControlDevices) {
            return <FormattedMessage id='intl-msg:accessNotAllowed'/>;
        }
        const {
            packageDeliverableWhitelist, devicesLoading, totalElements, onReload, onShowPage,
            onShowItem, onExportDevices, onLoadMore,
        } = this.props;
        const showLoadMore = totalElements > packageDeliverableWhitelist.length;
        const whitelistSerialNumbersAvailable = totalElements > 0 && !isEmpty(packageDeliverableWhitelist);

        return [
            <div className='padding-top-20'>
                <DeviceListTableToolbar
                    showExport={false}
                    whitelistSerialNumbersAvailable={whitelistSerialNumbersAvailable}
                    onExportDevices={onExportDevices}>
                    <span className='padding-top-10'>
                        {devicesLoading ? <Spinner/> : null}
                    </span>
                    <span className='padding-top-5'>
                        <FormattedMessage id='intl-msg:totalControlDevices.phrase' values={{
                            totalElements,
                            selectedCount: 0,
                        }}/>
                    </span>
                    <div className='display-inline-block'>
                        <a id='create-control-device-button'
                           className='btn btn-default'
                           onClick={onReload}>
                            <span className='rioglyph rioglyph-refresh' aria-hidden='true'></span>
                            <FormattedMessage id='intl-msg:reload'/>
                        </a>
                    </div>
                </DeviceListTableToolbar>
            </div>,
            <div className='padding-top-20 padding-bottom-20'>
                <DeviceListTable
                    controlDevices={packageDeliverableWhitelist}
                    hiddenColumns={[]}
                    columnOrder={columns}
                    canUpdateControlDevices={false}
                    allowSelection={true}
                    onShowItem={onShowItem}/>
            </div>,
            showLoadMore &&
            <div className='text-center padding-bottom-20'>
                <a id='load-more-button'
                   className='btn btn-default'
                   onClick={onLoadMore}>
                    <FormattedMessage id='intl-msg:loadMore'/>
                </a>
            </div>,
        ];
    }
}

export default DeliverableWhitelist;

DeliverableWhitelist.defaultProps = {
    // props
    canReadControlDevices: false,
    totalElements: 0,
    packageDeliverableWhitelist: [],
    devicesLoading: false,
    // functions
    onReload: noop,
    onShowPage: noop,
    onShowItem: noop,
    onExportDevices: noop,
    onLoadMore: noop,
};

DeliverableWhitelist.propTypes = {
    // props
    canReadControlDevices: PropTypes.bool,
    totalElements: PropTypes.number,
    packageDeliverableWhitelist: PropTypes.array,
    devicesLoading: PropTypes.bool,
    // functions
    onReload: PropTypes.func,
    onShowPage: PropTypes.func,
    onShowItem: PropTypes.func,
    onExportDevices: PropTypes.func,
    onLoadMore: PropTypes.func,
};
