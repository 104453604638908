import React, { useEffect, useState } from 'react';
import {
    controlDeviceEligibleCM4VersionsDataSelector,
    controlDeviceInfoSelector
} from '../../devices/selectors/controlDeviceSelectors';
import { useSelector } from 'react-redux';
import DefaultWhiteColumn from '../../base/components/DefaultWhiteColumn';
import ListTable from '../../base/components/table/ListTable';
import FieldOrNoData from '../../base/components/FieldOrNoData';
import { toShortSemanticVersion } from '../../base/utils/versionNumberConverter';
import TableSearch from '@rio-cloud/rio-uikit/lib/es/TableSearch';
import includes from 'lodash/fp/includes';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { DeliverableType } from '../../deliverables/constants/DeliverableType';
import { selectionByValue } from '../../base/utils/selectUtils';
import { FormattedMessage } from 'react-intl';
import { DeliverableModel } from '~/features/devices/models/DeliverableModel';
import { EligibleDerivableModel } from '~/features/devices/models/EligibleDerivableModel';

const deliverableVersionsColumnDescriptors = [{
    id: 'deliverableId',
    field: 'deliverableId',
    label: 'intl-msg:id',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'deliverableVersion',
    field: 'deliverableVersion',
    label: 'intl-msg:version',
    format: (field, item) => [<FieldOrNoData
        field={toShortSemanticVersion(field)}/>, item.eligibleDeliverableVersion ? [
        <span className='margin-right-5 margin-left-5'>→</span>,
        , <FieldOrNoData field={toShortSemanticVersion(item.eligibleDeliverableVersion)}/>
    ] : null],
},
    {
        id: 'deliverableType',
        field: 'deliverableType',
        label: 'intl-msg:type',
        format: field => <FieldOrNoData field={field}/>,
    }

];

const ALl = 'ALL';
const deliverableTypeOptions = [
    { id: ALl, label: ALl, value: ALl, selected: true },
    { id: 'FILE', label: DeliverableType.FILE, value: DeliverableType.FILE },
    { id: 'CM4G', label: DeliverableType.CM4G, value: DeliverableType.CM4G },

];

const DeviceDeliverables = ({ serialNumber }) => {
    const { installedDeliverables } = useSelector((state) => controlDeviceInfoSelector(state, { serialNumber }));
    const eligibleDeliverables = useSelector((state) => controlDeviceEligibleCM4VersionsDataSelector(state));
    const [search, setSearch] = useState<string>('');
    const [filterType, setFilterType] = useState(ALl);
    const [deliverables, setDeliverables] = useState(installedDeliverables);
    const [optionsWithSelection, setOptionsWithSelection] = useState(deliverableTypeOptions);

    const checkFilters = (deliverable) => filterType === ALl || deliverable.deliverableType === filterType;

    useEffect(() =>
        setOptionsWithSelection(selectionByValue(filterType)(deliverableTypeOptions)
        ), [filterType]);

    useEffect(() => {
            if (search !== '' || filterType !== ALl) {
                const filteredDeliverables = installedDeliverables?.filter(deliverable => includes(search.toLowerCase(), deliverable.deliverableId.toLowerCase()) && checkFilters(deliverable));
                setDeliverables(filteredDeliverables);
            } else {
                setDeliverables(installedDeliverables);
            }
        },
        [search, filterType]);

    const handleSelect = (item) => setFilterType(item.id);

    const mergeDeliverablesWithEligiblePackageVersions = (deliverables, eligibleDeliverableVersions: EligibleDerivableModel) => {
        let mergeDeliverables: DeliverableModel[] = [];
        // add installed packages
        deliverables?.forEach((currentDeliverable) => {
            let deliverable: DeliverableModel = currentDeliverable;
            // add eligible package version if available
            if (eligibleDeliverableVersions) {
                const eligibleDeliverableVersion = eligibleDeliverableVersions[currentDeliverable.deliverableId];
                if (eligibleDeliverableVersion) {
                    deliverable = { ...currentDeliverable, eligibleDeliverableVersion };
                    delete eligibleDeliverableVersions[currentDeliverable.deliverableId];
                }
            }
            mergeDeliverables.push(deliverable);
        });
        // add eligible only packages
        if (eligibleDeliverableVersions) {
            Object.entries(eligibleDeliverableVersions).forEach((currentDeliverableVersion) => {
                mergeDeliverables.push({
                    deliverableId: currentDeliverableVersion[0],
                });
            });
        }
        return mergeDeliverables;
    };
    return (
        <DefaultWhiteColumn className='padding-bottom-20'>
            <div className='display-flex table-toolbar  padding-bottom-5 align-items-end '>
                <div className={'width-200 '}>
                    <TableSearch
                        id='deliverableSearchInput'
                        value={search}
                        onChange={setSearch}
                        placeholder={'Search deliverable name'}
                    />
                </div>

                <div className='margin-left-15'>
                    <label className='control-label'>
                        <FormattedMessage id='intl-msg:deliverableType'/>
                    </label>
                    <Select options={optionsWithSelection}
                            onChange={handleSelect}/>
                </div>
            </div>
            <ListTable items={mergeDeliverablesWithEligiblePackageVersions(deliverables, { ...eligibleDeliverables })}
                       itemKey='deliverableId'
                       columnDescriptors={deliverableVersionsColumnDescriptors}/>
        </DefaultWhiteColumn>
    );
};

export default DeviceDeliverables;
