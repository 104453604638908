import React from 'react';
import map from 'lodash/fp/map';
import size from 'lodash/fp/size';
import ListTable from '~/features/base/components/table/ListTable';
import DeviceSystemStateContainer from '~/features/devices/components/DeviceSystemStateContainer';

export class DeviceListTableDetails extends ListTable {
    renderShowSystemNotificationsButton = (serialNumber) => {
        return <button type='button' className='margin-left-5' onClick={
            (event) => {
                event.preventDefault();
                event.stopPropagation();

                let openExpanders = {};
                if (this.state && this.state.openExpanders && this.state.openExpanders[serialNumber] !== undefined) {
                    openExpanders[serialNumber] = !this.state.openExpanders[serialNumber];
                    this.setState({
                        ...this.state,
                        openExpanders,
                    });
                } else {
                    openExpanders[serialNumber] = true;
                    this.setState({
                        openExpanders,
                    });
                }
            }}>
            {
                this.state && this.state.openExpanders && this.state.openExpanders[serialNumber] ?
                    <span className='rioglyph rioglyph-chevron-up text-size-10' aria-hidden='true'></span>
                    : <span className='rioglyph rioglyph-chevron-down text-size-10' aria-hidden='true'></span>
            }
        </button>;
    };

    renderTableRow = (item) => {
        const { itemKey, columnDescriptors } = this.props;
        const serialNumber = item.serialNumber;
        const selectionTableData = this.renderSelectionTableData(item);
        const showSystemNotificationsButton = this.renderShowSystemNotificationsButton(serialNumber);
        const tableData = map(columnDescriptor => this.renderTableData(item, columnDescriptor), columnDescriptors);
        if (size(tableData) > 3) {
            tableData[3] = <td className=''>
                <div className='padding-right-25'>
                    <div className='display-inline-block'>
                        {tableData[3]?.props?.children[0]}
                    </div>
                    <div className='display-inline-block'>
                        {showSystemNotificationsButton}
                    </div>
                </div>
            </td>;
        }
        const key = item[itemKey];
        const tableRows = [
            <tr className='tableRow' key={key} data-key={key} onClick={this.onRowClick}>
                {selectionTableData}
                {tableData}
            </tr>,
        ];
        this.state && this.state.openExpanders && this.state.openExpanders[serialNumber] ?
            tableRows.push(
                <tr key={key + '_'}>
                    <td style={{ borderTop: 0 }} colspan={columnDescriptors.length}>
                        <DeviceSystemStateContainer key={key + '__'} serialNumber={item.serialNumber}/>
                    </td>
                </tr>,
            ) : null;
        return tableRows;
    };
}
