import { call, put, takeLatest } from 'redux-saga/effects';

import {
    FILE_EDITOR_MODAL,
    FILE_DELETE_MODAL,
} from '~/features/base/constants/modals';

import { showModal } from '~/features/base/actions/ui/modalsActions';
import {
    resetFileEditor,
    changeFileEditor,
    SHOW_FILE_EDITOR_DIALOG,
    SHOW_FILE_DELETION_DIALOG, FETCH_FILE_DELIVERABLE_COUNT,
} from '~/features/deliverables/features/files/actions/fileEditorActions';
import {doHandleErrorSaga, postHTTP} from "~/features/base/sagas/sagaUtil";
import {DeliverableType} from "~/features/deliverables/constants/DeliverableType";
import {mergeAggregation} from "~/features/higherorder/actions/aggregationActions";
import {ENTITY_FILE_DELIVERABLE, ENTITY_PACKAGE_DELIVERABLE} from "~/features/base/constants/entities";
import {showErrorMessage} from "~/features/base/actions/ui/notificationsActions";
import {finishedAction} from "~/features/higherorder/transforms/actionTransforms";
import {
    getDeliverablesURL
} from "~/features/deliverables/features/packages/sagas/packageDeliverableSaga";

export function* doPrepareForNewSaga(fileDesc) {
    yield put(changeFileEditor({
        isNew: true,
        ...fileDesc,
    }));
    // Should suggest next patch  version
}

export function* doPrepareForExistingSaga(fileDesc) {
    yield put(changeFileEditor({
        isNew: false,
        ...fileDesc,
    }));
}

export function* doShowFileEditorDialogSaga(action) {
    yield put(resetFileEditor());
    if (action.payload.isNew) {
        yield call(doPrepareForNewSaga, action.payload);
    } else {
        yield call(doPrepareForExistingSaga, action.payload);
    }
    yield put(showModal({
        modalType: FILE_EDITOR_MODAL,
        modalProps: {},
    }));
}

export function* doShowFileDeletionDialogSaga(action) {
    yield put(resetFileEditor());
    const { fileId, fileVersion } = action.payload;
    yield put(changeFileEditor({
        fileId,
        fileVersion,
    }));
    yield put(showModal({
        modalType: FILE_DELETE_MODAL,
        modalProps: {},
    }));
}

export function* doFetchFileDeliverableCountSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ deliverableType: DeliverableType.FILE }));
        yield put(mergeAggregation({
            entityName: ENTITY_FILE_DELIVERABLE,
            scope: 'totalCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* showFileEditorDialogSaga() {
    yield takeLatest(SHOW_FILE_EDITOR_DIALOG, doShowFileEditorDialogSaga);
}

export function* showFileDeletionDialogSaga() {
    yield takeLatest(SHOW_FILE_DELETION_DIALOG, doShowFileDeletionDialogSaga);
}

export function* fetchFileDeliverableCountSaga() {
    yield takeLatest(FETCH_FILE_DELIVERABLE_COUNT, doFetchFileDeliverableCountSaga);
}
