import PropTypes from 'prop-types';
import React from 'react';

import {
    DISTROS_PATH,
    DELIVERABLES_PATH,
} from '~/features/base/constants/routes';
import { toShortVersion } from '~/features/base/utils/versionNumberConverter';

import Chip from '~/features/base/components/Chip';
import NoData from '~/features/base/components/NoData';
import { openInNewTab } from '~/features/base/utils/openInNewTab';
import classNames from 'classnames';

export const DistroChip = ({
    distroVersion,
    bsStyle,
    className,
    isShortVersion,
    toolTipMessage,
}) => {
    const shortDistroVersion = !isShortVersion ? toShortVersion(distroVersion) : distroVersion;
    if (shortDistroVersion === 'unknown') {
        return <NoData/>;
    }
    const handleOnClick = () => openInNewTab(`#${DELIVERABLES_PATH}/${DISTROS_PATH}?deliverable-version=${shortDistroVersion}`);

    return (

        <Chip toolTipMessage={toolTipMessage} onClick={handleOnClick} bsStyle={bsStyle}
              className={classNames('clickable', className)}>{shortDistroVersion}</Chip>
    );
};

export default DistroChip;

DistroChip.propTypes = {
    // props
    distroVersion: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.node,
        PropTypes.object.isRequired,
    ]),
    bsStyle: PropTypes.string,
    className: PropTypes.string,
};
