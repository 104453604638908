export const SHOW_FILE_EDITOR_DIALOG = 'SHOW_FILE_EDITOR_DIALOG';
export const SHOW_FILE_DELETION_DIALOG = 'SHOW_FILE_DELETION_DIALOG';

export const FETCH_FILE_DELIVERABLE_COUNT = 'FETCH_FILE_DELIVERABLE_COUNT'

export const RESET_FILE_EDITOR = 'RESET_FILE_EDITOR';
export const CHANGE_FILE_EDITOR = 'CHANGE_FILE_EDITOR';

export function showFileEditorDialog(payload) {
    return {
        type: SHOW_FILE_EDITOR_DIALOG,
        payload,
    };
}

export function showFileDeletionDialog(payload) {
    return {
        type: SHOW_FILE_DELETION_DIALOG,
        payload,
    };
}

export function resetFileEditor() {
    return {
        type: RESET_FILE_EDITOR,
    };
}

export function changeFileEditor(payload) {
    return {
        type: CHANGE_FILE_EDITOR,
        payload,
    };
}

export const fetchFileDeliverableCount = () => {
    return {
        type: FETCH_FILE_DELIVERABLE_COUNT,
    };
};