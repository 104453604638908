import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { DELIVERABLE_TYPE_DISTRO } from '~/features/deliverables/constants/deliverablesParameters';
import { env } from '~/env';

const deliverablesDefaultUrlsSuffix = {
    [DELIVERABLE_TYPE_APP]: 'packages/',
    [DELIVERABLE_TYPE_FILE]: 'release/file/',
    [DELIVERABLE_TYPE_DISTRO]: 'release/fullsystem/',
};
export const deliverablesDefaultUrl = (deliverableType: string): string => {
    const { downloadBaseUrl } = env.runtimeConfig;
    return ` ${downloadBaseUrl}${deliverablesDefaultUrlsSuffix[deliverableType]}`;
};
