import { env } from '~/env';
import { history } from './store';
import { routeStorage } from '~/features/login/storage';
import { reportErrorToSentry } from './sentry';

const param = (window, regex, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (_, it) => {
        result = it;
    });
    return result;
};

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');

    routeStorage.saveRoute(initialRoute);
};

export const attemptInitialSignIn = userManager => {
    const isFreshRedirect = Boolean(param(window, /access_token=([^&]+)/u));

    return userManager
        .signinSilent()
        .then(user => {
            const initialRoute = routeStorage.getRoute();
            if (initialRoute && isFreshRedirect) {
                history.replace(`/${initialRoute}`);
            }
            routeStorage.discardRoute();

            return Promise.resolve(user);
        })
        .catch(error => {

            if (env.runtimeConfig.login.preventRedirect) {
                // eslint-disable-next-line no-console
                console.warn('[feature/login] redirect prevented due to config. Error was', error);
            } else if (isFreshRedirect) {
                routeStorage.discardRoute();
                reportErrorToSentry(error);
            } else {
                saveCurrentRoute();
                userManager.signinRedirect();
            }

            return Promise.reject(new Error(`Need to sign in`));
        });
};
