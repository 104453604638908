import forEach from 'lodash/fp/forEach';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import TagList from '@rio-cloud/rio-uikit/lib/es/TagList';
import Tag from '@rio-cloud/rio-uikit/lib/es/Tag';

export class VehicleVariantsFormItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleVariantsInputText: '',
        };
    }

    onVehicleVariantsWhitelistChange = (vehicleVariantNames) => {
        this.props.onChange({
            vehicleVariantsWhitelist: vehicleVariantNames,
        });
    };

    onVehicleVariantsWhitelistInputTextChange = (value) => {
        this.setState({
            vehicleVariantsInputText: value,
        });
    };

    render() {
        const { vehicleVariants, whiteListedVehicleVariants } = this.props;
        const { vehicleVariantsInputText } = this.state;
        const vehicleVariantsInput = this.renderVehicleVariantsInput('vehicleVariants', this.props.vehicleVariantsLabel,
            whiteListedVehicleVariants, vehicleVariants, this.onVehicleVariantsWhitelistChange, vehicleVariantsInputText,
            this.onVehicleVariantsWhitelistInputTextChange);
        return vehicleVariantsInput;
    }

    renderVehicleVariantsInput(name, label, vehicleVariantNames, vehicleVariants, onChange, inputText, onChangeInputText) {
        const optionsWithSelection = [];
        if (!vehicleVariantNames) {
            vehicleVariantNames = [];
        }
        forEach(vehicleVariantName => {
            if (!vehicleVariantNames.includes(vehicleVariantName) && vehicleVariantName.toUpperCase().startsWith(inputText.toUpperCase())) {
                optionsWithSelection.push({
                    label: vehicleVariantName,
                });
            }
        }, vehicleVariants);

        const handleAddSelectedItem = (_, { suggestion }) => {
            vehicleVariantNames.push(suggestion.label);
            onChange(vehicleVariantNames);
            onChangeInputText('');
        };

        const handleRemoveSelectedItem = tagName => {
            onChange(vehicleVariantNames.filter(e => e !== tagName));
        };

        const handleSuggestionsChange = ({ value }) => {
            onChangeInputText(value);
        };

        const inputProps = {
            placeholder: 'Start typing ...',
            value: inputText,
        };

        return (
            <div className='form-group'>
                <label className='control-label'>
                    <FormattedMessage id={label}/>
                </label>
                <div className='form-group'>
                    <AutoSuggest
                        inputProps={inputProps}
                        suggestions={optionsWithSelection}
                        noItemMessage={<FormattedMessage id='intl-msg:multiSelect.prompt'/>}
                        onSuggestionSelected={handleAddSelectedItem}
                        onSuggestionsFetchRequested={handleSuggestionsChange}
                        openOnFocus
                    />
                    <TagList className={'margin-top-15'}>
                        {vehicleVariantNames.map(item => (
                            <Tag key={item} deletable onClick={() => handleRemoveSelectedItem(item)}>
                                {item}
                            </Tag>
                        ))}
                    </TagList>
                </div>
            </div>
        );
    }
}

export default VehicleVariantsFormItem;

// TODO Obviously it doesn't work without knowledge about whitelistingInfo
VehicleVariantsFormItem.defaultProps = {
    // props
    vehicleVariants: [],
    whiteListedVehicleVariants: [],
    vehicleVariantsLabel: 'intl-msg:vehicleVariants',
    // functions
    onChange: noop,
};

VehicleVariantsFormItem.propTypes = {
    // props
    vehicleVariants: PropTypes.array,
    whiteListedVehicleVariants: PropTypes.array,
    vehicleVariantsLabel: PropTypes.string,
    // functions
    onChange: PropTypes.func,
};
