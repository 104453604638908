import PropTypes from 'prop-types';
import React from 'react';

import PropertiesTable from '~/features/base/components/table/PropertiesTable';
import DefaultWhiteColumn from '~/features/base/components/DefaultWhiteColumn';

export const GroupReferences = ({ references, rowDescriptors }) => {
    return (
        <DefaultWhiteColumn className='padding-top-20 padding-bottom-20'>
            <PropertiesTable data={references} rowDescriptors={rowDescriptors}/>
        </DefaultWhiteColumn>
    );
};

export default GroupReferences;

GroupReferences.defaultProps = {
    references: {},
    rowDescriptors: [],
};

GroupReferences.propTypes = {
    // props
    references: PropTypes.object,
    rowDescriptors: PropTypes.array,
};
