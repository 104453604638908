import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import DeviceDetailsSidebarContainer from '~/features/devices/components/DeviceDetailsSidebarContainer';
import { connect } from 'react-redux';
import {
    getCurrentlySelectedDeviceInfoSelector,
    getSelectedTabIdSelector,
    showDeviceDetailsSidebarSelector,
} from '~/features/devices/selectors/controlDeviceSelectors';
import { toggleDeviceDetailsSidebar } from '~/features/devices/actions/ui/controlDeviceDetailsActions';
import DeviceDetailsTabs from '~/features/devices/components/DeviceDetailsTabs';
import DeviceSummary from '~/features/devices/components/DeviceSummary';
import {
    DETAILS,
    FILES,
    LOGS,
    PACKAGES,
    REPORT,
    DELIVERABLES,
} from '~/features/devices/constants/deviceDetailsTabs';
import DevicePropertiesContainer from '~/features/devices/components/DevicePropertiesContainer';
import DevicePackagesContainer from '~/features/devices/components/DevicePackagesContainer';
import DeviceReportContainer from '~/features/deviceReports/components/DeviceReportContainer';
import DeviceFileVersionsContainer from '~/features/devices/components/DeviceFileVersionsContainer';
import DeviceLogsContainer from '~/features/deviceLogs/components/DeviceLogsContainer';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import DeviceDeliverables from '~/features/devices/components/DeviceDeliverables';

export class DeviceDetailsSidebarRoutes extends PureComponent {

    getSelectedTabContainer = () => {
        const { controlDeviceInfo, selectedTabId } = this.props;
        const serialNumber = (controlDeviceInfo && controlDeviceInfo.serialNumber) || '---';
        switch (selectedTabId) {
            case DETAILS:
                return <DevicePropertiesContainer serialNumber={serialNumber}/>;
            case PACKAGES:
                return <DevicePackagesContainer serialNumber={serialNumber}/>;
            case FILES:
                return <DeviceFileVersionsContainer serialNumber={serialNumber}/>;
            case DELIVERABLES:
                return <DeviceDeliverables serialNumber={serialNumber}/>;
            case REPORT:
                return <DeviceReportContainer serialNumber={serialNumber}/>;
            case LOGS:
                return <DeviceLogsContainer serialNumber={serialNumber}/>;
            default:
                return <DevicePropertiesContainer serialNumber={serialNumber}/>;
        }
    };

    render() {
        const { controlDeviceInfo } = this.props;
        const serialNumber = (controlDeviceInfo && controlDeviceInfo.serialNumber) || '---';
        return (
            <div className='display-flex height-100pct'>
                <DeviceDetailsSidebarContainer serialNumber={serialNumber}>
                    {controlDeviceInfo ?
                        <div className={'padding-left-20 padding-right-20 height-100pct'}>
                            <div className='position-sticky top-0 bg-white padding-bottom-5 z-index-3 non-printable'>
                                <DeviceSummary {...this.props}
                                               serialNumber={serialNumber}
                                               controlDeviceInfo={controlDeviceInfo}/>
                                <DeviceDetailsTabs
                                    serialNumber={serialNumber}
                                    deviceType={controlDeviceInfo.type}/>
                            </div>
                            {
                                this.getSelectedTabContainer()
                            }
                        </div>
                        :
                        <div
                            className={'display-flex justify-content-center align-items-center margin-25 padding-25'}>
                            <Spinner text={'Loading'}/>
                        </div>
                    }
                </DeviceDetailsSidebarContainer>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        showDeviceDetailsSidebar: showDeviceDetailsSidebarSelector(state),
        selectedTabId: getSelectedTabIdSelector(state),
        controlDeviceInfo: getCurrentlySelectedDeviceInfoSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        toggleDeviceDetailsSidebar: () => {
            dispatch(toggleDeviceDetailsSidebar());
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsSidebarRoutes));
