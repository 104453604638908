import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
    FETCH_CM4G_DELIVERABLE_COUNT,
    FETCH_PACKAGE_DELIVERABLE,
    FETCH_PACKAGE_DELIVERABLE_COUNT,
    FETCH_PACKAGE_DELIVERABLE_VERSIONS,
} from '~/features/deliverables/features/packages/actions/packageDeliverableActions';

import { mergeEntity } from '~/features/higherorder/actions/entityActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';

import {
    parsePackageDeliverable,
    parsePackageDeliverableVersions,
} from '~/features/deliverables/features/packages/transforms/parsePackageDeliverables';

import {
    ENTITY_CM4G_DELIVERABLE,
    ENTITY_DELIVERABLES_VERSIONS,
    ENTITY_PACKAGE_DELIVERABLE
} from '~/features/base/constants/entities';
import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';

import { saveFilterSettings } from '~/features/base/actions/ui/filterActions';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

export function* getDeliverablesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* doFetchPackageDeliverableCountSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ deliverableType: DeliverableType.APP }));
        yield put(mergeAggregation({
            entityName: ENTITY_PACKAGE_DELIVERABLE,
            scope: 'totalCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchCm4gDeliverableCountSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        const response = yield call(postHTTP, `${url}/count`, JSON.stringify({ deliverableType: DeliverableType.CM4G }));
        yield put(mergeAggregation({
            entityName: ENTITY_CM4G_DELIVERABLE,
            scope: 'totalCount',
        }, response.count));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchPackageDeliverableSaga(action) {
    try {
        const url = yield call(getDeliverablesURL);
        // FIXME Should not be required
        const encodedAppId = encodeURIComponent(action.payload.packageId);
        const encodedAppVersion = encodeURIComponent(action.payload.shortPackageVersion);
        const response = yield call(getHTTP, `${url}/${DeliverableType.APP}/${encodedAppId}/${encodedAppVersion}`);
        const parsedResponse = parsePackageDeliverable(response);
        yield put(mergeEntity(parsedResponse, { entityName: ENTITY_PACKAGE_DELIVERABLE }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchPackageVersionsSaga(action) {
    yield put(startLoading(ENTITY_PACKAGE_DELIVERABLE));
    try {
        const url = yield call(getDeliverablesURL);
        const encodedPackageId = encodeURIComponent(action.payload.packageId);
        const response = yield call(getHTTP, `${url}/${DeliverableType.APP}/${encodedPackageId}/versions`);
        const parsedResponse = parsePackageDeliverableVersions({ packageVersions: response.content });
        yield put(saveFilterSettings(ENTITY_DELIVERABLES_VERSIONS, parsedResponse));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_PACKAGE_DELIVERABLE));
    yield put(finishedAction(action.type));
}

export function* fetchPackageDeliverableCountSaga() {
    yield takeLatest(FETCH_PACKAGE_DELIVERABLE_COUNT, doFetchPackageDeliverableCountSaga);
}

export function* fetchCm4gDeliverableCountSaga() {
    yield takeLatest(FETCH_CM4G_DELIVERABLE_COUNT, doFetchCm4gDeliverableCountSaga);
}

export function* fetchPackageDeliverableSaga() {
    yield takeLatest(FETCH_PACKAGE_DELIVERABLE, doFetchPackageDeliverableSaga);
}

export function* fetchPackageVersionsSaga() {
    yield takeLatest(FETCH_PACKAGE_DELIVERABLE_VERSIONS, doFetchPackageVersionsSaga);
}

