import compact from 'lodash/fp/compact';
import join from 'lodash/fp/join';
import isEmpty from 'lodash/fp/isEmpty';

import { call, takeEvery } from 'redux-saga/effects';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';

import { SHOW_ERROR_MESSAGE } from '~/features/base/actions/ui/notificationsActions';
import { DELETE_GROUP } from '~/features/groups/actions/groupActions';

const deletionConflictToMessage = conflict => {
    const distroLabel = !isEmpty(conflict.distros) ? `distros ${join(', ', conflict.distros)}` : '';
    let packagesLabel = '';
    if (!isEmpty(conflict.packages)) {
        conflict.packages.map((currentPackage, index) => {
            packagesLabel += currentPackage.packageId + '-' + currentPackage.packageVersion;
            conflict.packages.length - 1 > index ? packagesLabel += ', ' : '';
        });
    }
    const label = join(' and ', compact([distroLabel, packagesLabel]));
    return `The group is used for whitelisting and blacklisting in ${label}`;
};

export const doShowNotificationSaga = (title, message) => {
    Notification.error(message, title);
};

export function* doShowErrorMessageSaga({ actionType, error }) {
    if (!error) {
        return;
    }
    const body = error.body ? JSON.parse(error.body) : error.message;
    const message = body.message || body.Message || body.error || body;

    switch (actionType) {
        case DELETE_GROUP:
            return yield call(doShowNotificationSaga, 'Conflict', deletionConflictToMessage(message));
        default:
            return yield call(doShowNotificationSaga, error.name, message);
    }
}

export function* showErrorMessagesSaga() {
    yield takeEvery(SHOW_ERROR_MESSAGE, doShowErrorMessageSaga);
}
