import React from 'react';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import { MultiselectProps } from '@rio-cloud/rio-uikit/types';
import { FormattedMessage } from 'react-intl';

interface MultiselectOTAProps {
    hasError: boolean;
}

const MultiSelectOTA: React.FC<MultiselectOTAProps & Partial<MultiselectProps<any>>> = (props) => {

    const highlightError = props.hasError ? 'form-group has-feedback has-error' : '';
    return (
        <>
            <div className={highlightError}>
                <Multiselect
                    {...props}
                /></div>
            {props.hasError &&
                <div className={'form-group has-feedback has-error'}>
                        <span className='help-block'>
                        <span><FormattedMessage id='intl-msg:form:requiredField'/></span>
                        </span>
                </div>
            }</>
    );
};

export default MultiSelectOTA;
