import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import {ENTITY_CM4G, ENTITY_PACKAGE} from '~/features/base/constants/entities';

import {
    FETCH_CM4G_COUNT,
    FETCH_CM4GS,
    FETCH_PACKAGE_COUNT,
    FETCH_PACKAGES,
} from '~/features/deliverables/features/packages/actions/packageActions';

import { mergeEntities, mergeEntity } from '~/features/higherorder/actions/entityActions';
import { mergeAggregation } from '~/features/higherorder/actions/aggregationActions';
import { endLoading, startLoading } from '~/features/higherorder/actions/loadingActions';

import { showErrorMessage } from '~/features/base/actions/ui/notificationsActions';

import { getDeviceManagementBackend } from '~/features/base/selectors/backendSelectors';

import { doHandleErrorSaga, getHTTP, postHTTP } from '~/features/base/sagas/sagaUtil';

import { finishedAction } from '~/features/higherorder/transforms/actionTransforms';
import { DeliverableType } from '~/features/deliverables/constants/DeliverableType';

export function* getPackagesURL() {
    const serviceURL = yield select(getDeviceManagementBackend);
    return `${serviceURL}/v1/admin/deliverable`;
}

export function* doFetchPackageCountSaga(action) {
    try {
        const url = yield call(getPackagesURL);
        const response = yield call(postHTTP, `${url}/count/deliverable-ids-per-deliverable-type`, JSON.stringify({
            deliverableType: DeliverableType.APP,
        }));
        yield put(mergeAggregation({
            entityName: ENTITY_PACKAGE,
            scope: 'totalCount',
        }, response.APP));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}

export function* doFetchCm4gCountSaga(action) {
    try {
        const url = yield call(getPackagesURL);
        const response = yield call(postHTTP, `${url}/count/deliverable-ids-per-deliverable-type`, JSON.stringify({
            deliverableType: DeliverableType.CM4G,
        }));
        yield put(mergeAggregation({
            entityName: ENTITY_CM4G,
            scope: 'totalCount',
        }, response.CM4G));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(finishedAction(action.type));
}


export function* doFetchPackagesSaga(action) {
    yield put(startLoading(ENTITY_PACKAGE));
    try {
        const url = yield call(getPackagesURL);
        const response = yield call(postHTTP, `${url}/search/deliverable-ids?page=0&size=400`, JSON.stringify({
            deliverableType: DeliverableType.APP,
        }));
        yield put(mergeEntity(response.content, { entityName: ENTITY_PACKAGE }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_PACKAGE));
    yield put(finishedAction(action.type));
}

export function* doFetchCm4gsSaga(action) {
    yield put(startLoading(ENTITY_CM4G));
    try {
        const url = yield call(getPackagesURL);
        const response = yield call(postHTTP, `${url}/search/deliverable-ids?page=0&size=400`, JSON.stringify({
            deliverableType: DeliverableType.CM4G,
        }));
        yield put(mergeEntity(response.content, { entityName: ENTITY_CM4G }));
    } catch (error) {
        yield fork(doHandleErrorSaga, action.type, error);
        yield put(showErrorMessage(action.type, error));
    }
    yield put(endLoading(ENTITY_CM4G));
    yield put(finishedAction(action.type));
}


export function* fetchPackageCountSaga() {
    yield takeLatest(FETCH_PACKAGE_COUNT, doFetchPackageCountSaga);
}

export function* fetchPackagesSaga() {
    yield takeLatest(FETCH_PACKAGES, doFetchPackagesSaga);
}

export function* fetchCm4gCountSaga() {
    yield takeLatest(FETCH_CM4G_COUNT, doFetchCm4gCountSaga);
}

export function* fetchCm4gsSaga() {
    yield takeLatest(FETCH_CM4GS, doFetchCm4gsSaga);
}