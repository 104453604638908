import { configureStorage } from './storage';

export const extractAccessTokenFromWindowLocation = window => {
    if (!window || !window.location || !window.location.href) {
        return undefined;
    }

    if (typeof window.location.href !== 'string') {
        return undefined;
    }

    let token;
    window.location.href.replace(/access_token=([^&]+)/u, (_, it) => {
        token = it;
    });
    // TODO DO NOT FORGET TO REMOVE
    console.log("EXTRACT_ACCESS_TOKEN_FROM_WINDOWS_LOCATION:")
    console.log(token)
    return token;
};

export const configureAccessToken = (window, storage) => {
    const urlToken = extractAccessTokenFromWindowLocation(window);
    // TODO DO NOT FORGET TO REMOVE
    console.log("ACCESS_TOKEN:")
    console.log(urlToken)
    if (urlToken) {
        storage.saveAccessToken(urlToken);
    }

    return storage;
};

export const accessToken = configureAccessToken(typeof window === 'undefined' ? null : window, configureStorage());
