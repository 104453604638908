import React from 'react';
import { FormattedTime } from 'react-intl';

import DistroChip from '~/features/deliverables/components/DistroChip';
import ShortBaseSwVersion from '~/features/baseSwVersions/components/ShortBaseSwVersion';
import DeviceState from '~/features/devices/components/DeviceState';
import DeviceOffline from '~/features/devices/components/DeviceOffline';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import DeviceType from '~/features/devices/components/DeviceType';
import DeviceHealthState from '~/features/devices/components/DeviceHealthState';
import { copyToClipboard } from '~/features/apps/utils/appsUtils';
import DeviceProductionMode from '~/features/devices/components/DeviceProductionMode';

export const CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY = 'CONTROL_DEVICE_COLUMN_SETTINGS_LOCAL_STORAGE_KEY';

const copyClipboard = (field) => (
    <span key='field' className='rioglyph rioglyph-duplicate text-color-dark text-size-15 padding-left-5'
          onClick={(event) => {
              event.stopPropagation();
              copyToClipboard(field, true);
          }}></span>);

export const deviceColumnDescriptors = [
    {
        id: 'deviceId',
        field: 'serialNumber',
        label: 'intl-msg:deviceId',
        format: (field, item) => (
            <span className='white-space-nowrap'>
                <span className='margin-right-5'>
                    <FieldOrNoData field={field}/>
                </span>
                {copyClipboard(field)}
            </span>
        ),
    },
    {
        id: 'serialNumber',
        field: 'originalSerialNumber',
        label: 'intl-msg:hwSerial',
        format: (field, item) => (
            <span className='white-space-nowrap'>
                <span className='margin-right-5'>
                    <FieldOrNoData field={field}/>
                </span>
                <DeviceType controlDevice={item}/>
                <DeviceState controlDevice={item}/>
                <DeviceProductionMode controlDevice={item}/>
                {copyClipboard(field)}
            </span>
        ),
    }, {
        id: 'vin',
        field: 'vin',
        label: 'intl-msg:VIN',
        className: 'hidden-xs hidden-ls hidden-sm',
        format: field => [<FieldOrNoData key='fieldOrNoData' field={field}/>,
            field && copyClipboard(field),
        ],
    }, {
        id: 'deviceHealthState',
        field: 'status',
        label: 'intl-msg:deviceHealthState',
        format: (field, item) =>
            [<DeviceHealthState controlDevice={item}/>],

    }, {
        id: 'hwVariant',
        field: 'hwVariant',
        label: 'intl-msg:hwVariant',
        className: 'hidden-xs hidden-ls hidden-sm',
        format: (field, item) => [
            <div key={'hmVariant'}>
                <FieldOrNoData field={field}/>
            </div>
        ],
    }, {
        id: 'vehicleVariant',
        field: 'vehicleVariant',
        label: 'intl-msg:vehicleVariant',
        format: field => [
            <div key={'vehicleVariant'}>
                <FieldOrNoData field={field}/>
            </div>
        ],
    },{
        id: 'lastCheckForUpdateTimestamp',
        field: 'lastCheckForUpdateTimestamp',
        label: 'intl-msg:lastCheckForUpdateTimestamp',
        format: field => {
            return (
                <span className='white-space-nowrap'>
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
                <DeviceOffline timestamp={field}/>
            </span>
            );
        },
        sortable: true,
    }, {
        id: 'registrationTimestamp',
        field: 'registrationTimestamp',
        label: 'intl-msg:registrationTimestamp',
        className: 'hidden-xs hidden-ls hidden-sm hidden-md',
        format: field => {
            return (
                <FieldOrNoData field={field ? <FormattedTime value={field} year='numeric'
                                                             month='2-digit' day='2-digit'/> : null}/>
            );
        },
        hidden: true,
    }];
