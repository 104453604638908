import React from 'react';
import FieldOrNoData from '~/features/base/components/FieldOrNoData';
import { FormattedMessage, FormattedTime } from 'react-intl';
import Priority from '~/features/base/components/Priority';
import ReleaseState from '~/features/base/components/ReleaseState';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import WhitelistingDetails from '~/features/base/components/WhitelistingDetails';
import size from 'lodash/fp/size';
import join from 'lodash/fp/join';
import FieldVersionNumberDependencies from '~/features/base/components/FieldVersionNumberDependencies';
import { DISTRO } from '~/features/base/constants/filterOptions';
import DistroChip from '~/features/deliverables/components/DistroChip';
import ReasonForChange from '~/features/base/components/ReasonForChange';
import BundleDeliverableDetails from '~/features/deliverables/components/details/BundleDeliverablesDetails';

export const fileColumnDescriptors = [{
    id: 'fileName',
    field: 'fileName',
    label: 'intl-msg:fileName',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'sizeInBytes',
    field: 'sizeInBytes',
    label: 'intl-msg:size',
    format: field => <FieldOrNoData field={field}/>,
}, {
    id: 'uploadedTime',
    field: 'uploadedTime',
    label: 'intl-msg:creationTime',
    format: field => <FormattedTime value={field} year='numeric' month='2-digit' day='2-digit'/>,
}];

const commonColumnDescriptors = [
    {
        field: 'description',
        label: 'intl-msg:description',
        format: field => <FieldOrNoData field={field}/>,
    }, {
        field: 'deviceType',
        label: 'intl-msg:deviceType',
        format: (field) =>
            <div>
                <span className='margin-right-15'>{field}</span>
            </div>,
    }, {
        field: 'compatibility',
        label: 'intl-msg:versions.dependencies',
        format: (field, data) => field || (data?.dependencies?.deliverables) ?
            <FieldVersionNumberDependencies field={field} data={data}/> : null,
    }, {
        field: 'whitelisting',
        label: 'intl-msg:whitelistingInfo',
        format: field => <WhitelistingDetails whitelistingInfo={field}/>,
    }, {
        field: 'mandatoryWhitelisting',
        label: 'intl-msg:mandatoryWhitelistingInfo',
        format: field => <WhitelistingDetails whitelistingInfo={field}/>,
    }, {
        id: 'downloadUrl',
        field: 'downloadUrl',
        label: 'intl-msg:downloadUrl',
        format: field => <FieldOrNoData field={field}/>,
    }, {
        field: 'priority',
        label: 'intl-msg:priority',
        format: field => <Priority priority={field}/>,
    }, {
        field: 'networkCodeWhitelist',
        label: 'intl-msg:networkCodePreset',
        format: field => {
            if (field && size(field) === 1) {
                return join(', ', field);
            } else if (field && size(field) === 2) {
                return <><span className='text-bold'><FormattedMessage id={field[0]}/></span><FieldOrNoData
                    field={field[1]}/></>;
            }
            return (
                <span className='label label-primary'>
                <FormattedMessage id='intl-msg:noNetworkCodes'/>
            </span>
            );

        },
    }];

export const deliverableColumnDescriptors = {
        DISTRO: [
            ...commonColumnDescriptors,
            {
                field: 'shortDistroVersion',
                label: 'intl-msg:distroVersion',
                format: (field, distro) => [
                    <DistroChip key={'distroChip'} distroVersion={field} isShortVersion className='margin-right-15'/>,
                    <ReleaseState key={'releaseState'} releaseState={distro.releaseState}/>,
                ],
                type: DISTRO,
            },
            {
                field: 'shortBaseSwVersion',
                label: 'intl-msg:shortBaseSwVersion',
                format: (field, distro) =>
                    <div>
                        <span className="margin-right-15">{field}</span>
                    </div>,
                type: DISTRO,
            }],
    FILE: [
        ...commonColumnDescriptors,
        {
            field: 'reasonForChange',
            label: 'intl-msg:reasonForChange',
            format: (field) => <FieldOrNoData field={field ? <ReasonForChange reasonForChange={field}/> : null}/>,
        },
        {
            field: 'releaseState',
            label: 'intl-msg:releaseState',
            format: (field) => <FieldOrNoData field={field}/>
            ,
        },
        {
            field: 'standalone',
            label: 'intl-msg:deliverable.standalone',
            format: field => {
                return (<span>
                            {field ? <FormattedMessage id={'intl-msg:yes'}/> : <FormattedMessage id={'intl-msg:no'}/>}
                        </span>);
            },
        },
        {
            field: 'owningContext',
            label: 'intl-msg:context',
            format: field => <FieldOrNoData field={field}/>,
        },
        {
            field: 'fileInfo',
            label: 'intl-msg:recipient',
            format: field => <FieldOrNoData field={field && field.recipient}/>,
        },
    ],
    APP: [
        ...commonColumnDescriptors,
        {
            field: 'releaseState',
            label: 'intl-msg:releaseState',
            format: (field) => <FieldOrNoData field={field}/>,
        },
        {
            field: 'appInfo',
            label: 'intl-msg:bundleMmtInfo',
            format: field => {
                if (!!field && !!field.mmtInfo) {
                    return (
                        <table>
                            <tr>
                                <td className="text-color-dark width-200">
                                    <FormattedMessage id="intl-msg:packageId"/>
                                </td>
                                    <td>
                                        {field.mmtInfo.appId}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-color-dark width-200'>
                                        <FormattedMessage id='intl-msg:packageVersion'/>
                                    </td>
                                    <td>
                                        {toShortSemanticVersion(field.mmtInfo.appVersion)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-color-dark width-200'>
                                        <FormattedMessage id='intl-msg:downloadBaseUrl'/>
                                    </td>
                                    <td>
                                        {field.mmtInfo.downloadUrl}
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-color-dark width-200'>
                                        <FormattedMessage id='intl-msg:description'/>
                                    </td>
                                    <td>
                                        {field.mmtInfo.additionalData}
                                    </td>
                                </tr>
                            </table>
                        );
                    }
                    return (
                        <FieldOrNoData field={field && field.mmtInfo}/>
                    );
                },
            },
        ],
        CM4G: [
            {
                field: 'reasonForChange',
                label: 'intl-msg:reasonForChange',
                format: (field) => <FieldOrNoData field={field ? <ReasonForChange reasonForChange={field}/> : null}/>,
            },
            {
                field: 'description',
                label: 'intl-msg:description',
                format: field => <FieldOrNoData field={field}/>,
            },
            {
                field: 'deviceType',
                label: 'intl-msg:deviceType',
                format: (field) =>
                    <div>
                        <span className='margin-right-15'>{field}</span>
                    </div>,
            },
            {
                field: 'compatibility',
                label: 'intl-msg:versions.dependencies',
                format: (field, data) => field || (data?.dependencies?.deliverables) ?
                    <FieldVersionNumberDependencies field={field} data={data}/> : null,
            },
            {
                field: 'whitelisting',
                label: 'intl-msg:whitelistingInfo',
                format: field => <WhitelistingDetails whitelistingInfo={field}/>,
            },
            {
                field: 'standalone',
                label: 'intl-msg:deliverable.standalone',
                format: field => {
                    return (<span>
                            {field ? <FormattedMessage id={'intl-msg:yes'}/> : <FormattedMessage id={'intl-msg:no'}/>}
                        </span>);
                },
            },
            {
                id: 'downloadUrl',
                field: 'downloadUrl',
                label: 'intl-msg:downloadUrl',
                format: field => <FieldOrNoData field={field}/>,
            }, {
                field: 'priority',
                label: 'intl-msg:priority',
                format: field => <Priority priority={field}/>,
            }, {
                field: 'networkCodeWhitelist',
                label: 'intl-msg:networkCodePreset',
                format: field => {
                    if (field && size(field) === 1) {
                        return join(', ', field);
                    } else if (field && size(field) === 2) {
                        return <><span className='text-bold'><FormattedMessage id={field[0]}/></span><FieldOrNoData
                            field={field[1]}/></>;
                    }
                    return (
                        <span className='label label-primary'>
                <FormattedMessage id='intl-msg:noNetworkCodes'/>
            </span>
                    );

                },
            },

        ],
        BUNDLE: [
            {
                field: 'bundleInfo',
                label: 'intl-msg:ivd',
                format: field => <FieldOrNoData field={field?.ivd}/>,
            },
            {
                field: 'reasonForChange',
                label: 'intl-msg:reasonForChange',
                format: (field) => <FieldOrNoData field={field ? <ReasonForChange reasonForChange={field}/> : null}/>,
            },
            {
                field: 'description',
                label: 'intl-msg:description',
                format: field => <FieldOrNoData field={field}/>,
            },
            {
                field: 'deviceType',
                label: 'intl-msg:deviceType',
                format: (field) =>
                    <div>
                        <span className='margin-right-15'>{field}</span>
                    </div>,
            },
            {
                field: 'compatibility',
                label: 'intl-msg:versions.dependencies',
                format: (field, data) => field || (data?.dependencies?.deliverables) ?
                    <FieldVersionNumberDependencies field={field} data={data}/> : null,
            },
            {
                field: 'bundleDeliverables',
                label: 'intl-msg:bundleDeliverables',
                format: (field, data) => <BundleDeliverableDetails field={field} data={data}/>,
            },
            {
                field: 'whitelisting',
                label: 'intl-msg:whitelistingInfo',
                format: field => <WhitelistingDetails whitelistingInfo={field}/>,
            },
            {
                field: 'priority',
                label: 'intl-msg:priority',
                format: field => <Priority priority={field}/>,
            }, {
                field: 'networkCodeWhitelist',
                label: 'intl-msg:networkCodePreset',
                format: field => {
                    if (field && size(field) === 1) {
                        return join(', ', field);
                    } else if (field && size(field) === 2) {
                        return <><span className='text-bold'><FormattedMessage id={field[0]}/></span><FieldOrNoData
                            field={field[1]}/></>;
                    }
                    return (
                        <span className='label label-primary'>
                <FormattedMessage id='intl-msg:noNetworkCodes'/>
            </span>
                    );

                },
            },
        ],
    }
;
