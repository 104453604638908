export const FETCH_PACKAGE_COUNT = 'FETCH_PACKAGE_COUNT';
export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const FETCH_CM4G_COUNT = 'FETCH_CM4G_COUNT';
export const FETCH_CM4GS = 'FETCH_CM4GS'

export const fetchPackageCount = () => {
    return {
        type: FETCH_PACKAGE_COUNT,
    };
};

export const fetchPackages = () => {
    return {
        type: FETCH_PACKAGES,
    };
};

export const fetchCm4gCount = () => {
    return {
        type: FETCH_CM4G_COUNT,
    };
};

export const fetchCm4gs = () => {
    return {
        type: FETCH_CM4GS,
    };
};
