import get from 'lodash/fp/get';

import {
    ENTITY_CM4G_DELIVERABLE,
    ENTITY_PACKAGE,
    ENTITY_PACKAGE_DELIVERABLE
} from '~/features/base/constants/entities';

import {
    entityStoreSelector,
    aggregationStoreSelector, indicesStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';

import {
    toPackageIdAndVersion
} from '~/features/deliverables/features/packages/transforms/parsePackageDeliverables';
import { createSelector } from 'reselect';
import map from 'lodash/map';

const packageDeliverableAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_PACKAGE_DELIVERABLE];
const packageDeliverableEntitiesSelector = state => entityStoreSelector(state)[ENTITY_PACKAGE_DELIVERABLE];
const packageEntitiesSelector = state => entityStoreSelector(state)[ENTITY_PACKAGE];
const packageSortedIdsSelector = state => indicesStoreSelector(state)[ENTITY_PACKAGE];

export const packageDeliverableSelector = (state, { packageId, shortPackageVersion }) => {
    return packageDeliverableEntitiesSelector(state)[toPackageIdAndVersion(packageId, shortPackageVersion)];
};

export const packageDeliverableCountSelector = state => get('totalCount', packageDeliverableAggregationsSelector(state));

export const packagesSelector = createSelector(
    [packageSortedIdsSelector, packageEntitiesSelector],
    (sortedIds, entities) => {
        return map(id => entities[id], sortedIds);
    },
);

const cm4gDeliverableAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_CM4G_DELIVERABLE];

export const cm4gDeliverableCountSelector = state => get('totalCount', cm4gDeliverableAggregationsSelector(state));

