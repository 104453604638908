import size from 'lodash/fp/size';
import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/lib/es/ButtonDropdown';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export class DeviceListTableToolbar extends PureComponent {
    render() {
        const { children, showExport, totalElements } = this.props;
        const exportDropdown = this.renderExportDropdown(totalElements);
        return (
            <div className='row device-list-table-toolbar'>
                <div className='col-xs-6'>
                    <div className='btn-toolbar'>
                        {children}
                    </div>
                </div>
                {showExport &&
                    <div className='col-xs-6'>
                        <div className='btn-toolbar pull-right'>
                            {exportDropdown}
                        </div>
                    </div>
                }
            </div>
        );
    }

    renderExportDropdown(totalElements) {
        const { whitelistSerialNumbersAvailable } = this.props;
        const items = [];
        items.push(
            {
                value: [
                    <span className='rioglyph rioglyph-cards-grid padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportCSV'/>,
                ],
                onSelect: () => this.props.onExportDevices('csv'),
            },
        );
        items.push(
            {
                value: [
                    <span className='rioglyph rioglyph-cards-list padding-right-5' aria-hidden='true'></span>,
                    <FormattedMessage id='intl-msg:exportSerials'/>,
                ],
                onSelect: () => this.props.onExportDevices('serials'),
            },
        );
        return (
            size(items) ?
                whitelistSerialNumbersAvailable ?
                    <ButtonDropdown
                        id='device-list-exports'
                        title={
                            <span>
                                <span className='rioglyph rioglyph-download' aria-hidden='true'/>&nbsp;&nbsp;
                                <FormattedMessage id='intl-msg:export'/>
                            </span>
                        }
                        items={items}
                    />
                    :
                    <OverlayTrigger placement='top' delay={totalElements === 0 ? 20000 : 0} overlay={
                        <Tooltip id='tooltip' className='left-top'>
                            <FormattedMessage id={'intl-msg:distros.tooManyWhitelistedDevices'}/>
                        </Tooltip>}>
                        <div>
                            <ButtonDropdown
                                disabled={true}
                                id='device-list-exports'
                                title={
                                    <span>
                                        <span className='rioglyph rioglyph-download' aria-hidden='true'/>&nbsp;&nbsp;
                                        <FormattedMessage id='intl-msg:export'/>
                                    </span>
                                }
                                items={items}
                            />
                        </div>
                    </OverlayTrigger>
                : null
        );
    }
}

export default DeviceListTableToolbar;

DeviceListTableToolbar.defaultProps = {
    // props
    whitelistSerialNumbersAvailable: false,
    totalElements: 0,
    showExport: true,
    // functions
    onExportDevices: noop,
};

DeviceListTableToolbar.propTypes = {
    // props
    whitelistSerialNumbersAvailable: PropTypes.bool,
    totalElements: PropTypes.number,
    showExport: PropTypes.bool,
    // functions
    onExportDevices: PropTypes.func,
};
