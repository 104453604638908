import {
    createApi,
} from '@reduxjs/toolkit/query/react';
import { VehicleDetailsView } from '~/api/models';
import { dynamicBaseQuery } from '~/api/common/dynamicBaseQuery';

const baseUrl: string = 'v1/admin/vehicle-group';

export const vehicleGroupApi = createApi({
    reducerPath: 'vehicleGroupApi',
    baseQuery: dynamicBaseQuery,
    endpoints: (builder) => ({

        fetchVehicleGroups: builder.query<VehicleDetailsView, {}>({
            query: () => ({
                url: `${baseUrl}`,
                method: 'GET',
            }),
        })
    }),
});

export const { useFetchVehicleGroupsQuery } = vehicleGroupApi;
