import compact from 'lodash/fp/compact';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import map from 'lodash/fp/map';

import { createSelector } from 'reselect';

import {
    PERMISSION_CM4G_DELIVERABLE_CREATE,
    PERMISSION_CM4G_DELIVERABLE_UPDATE,
    PERMISSION_CM4G_DELIVERABLE_DELETE,
} from '~/features/base/constants/permissions';

import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import { entityStoreSelector, aggregationStoreSelector,
    indicesStoreSelector} from '~/features/higherorder/selectors/dataSelectors';
import {
    ENTITY_DISTRO,
    ENTITY_LIVE_DISTROS
} from '~/features/base/constants/entities';

const distroEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DISTRO];
const liveDistrosEntitiesSelector = state => entityStoreSelector(state)[ENTITY_LIVE_DISTROS];
const distroPagesSelector = state => indicesStoreSelector(state)[ENTITY_DISTRO];
const distroAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_DISTRO];

export const distroEditorSelector = state => state.ui.distroEditor;

export const distroCountsByReleaseStateSelector = state => distroAggregationsSelector(state).countsByReleaseState;

export const distroSelector = (state, ownProps) =>
    distroEntitiesSelector(state)[ownProps.shortDistroVersion];

export const liveDistrosSelector = (state) =>
    liveDistrosEntitiesSelector(state);

export const canCreateDistrosSelector = state =>
    permissionsSelector(state).includes(PERMISSION_CM4G_DELIVERABLE_CREATE);

export const canUpdateDistrosSelector = state =>
    permissionsSelector(state).includes(PERMISSION_CM4G_DELIVERABLE_UPDATE);

export const canDeleteDistrosSelector = state =>
    permissionsSelector(state).includes(PERMISSION_CM4G_DELIVERABLE_DELETE);

export const distroPageNumberSelector = state =>
    get('number', distroPagesSelector(state));


export const distroVersionsSelector = createSelector(
    [distroPagesSelector, distroEntitiesSelector],
    (pages, entities) => {
        return map(id => entities[id], getOr([], 0, pages));
    }
);

export const distroPageItemsSelector = createSelector(
    [distroPagesSelector, distroEntitiesSelector, distroPageNumberSelector],
    (pages, entities, pageNumber) => {
        const result = compact(map(id => entities[id], getOr([], pageNumber, pages)));
        return result;
    }
);

