// Generic paths
export const LIST_PATH = 'list';
export const DETAILS_PATH = 'details';

// Primary paths
export const OVERVIEW_PATH = 'overview';
export const DEVICES_PATH = 'devices';
export const OLD_VEHICLES_PATH = 'oldVehicles';
export const VEHICLES_PATH = 'vehicles';

export const GROUPS_PATH = 'groups';
export const DISTROS_PATH = 'distros';
export const APPS_PATH = 'apps';
export const PACKAGES_PATH = 'packages';
export const FILES_PATH = 'files';
export const CM4G_PATH = 'cm4g';
export const BUNDLE_PATH = 'bundles';
export const ARTIFACTS_MANAGEMENT_PATH = 'artifacts';
export const DELIVERABLES_MANAGEMENT_PATH = 'deliverables';
//we keep it for while to make the old urls used in tickets compatible
export const DELIVERABLES_MANAGEMENT_NEO_PATH = 'deliverables';
export const REPORTS_BILLING_PATH = 'reportsBilling';
export const FOTA_L3_PATH = 'fotaL3';
export const WALKTHROUGH_PATH = 'walkthrough';

// Secondary paths
export const HOME_PATH = 'home';
export const PARAMETERS_PATH = 'parameters';
export const SYSTEM_STATE_NOTIFICATIONS_PATH = 'state';
export const WHITELIST_PATH = 'whitelist';
export const HISTORY_PATH = 'history';
export const DEVICES_WITH_LOGS_PATH = 'devices-with-logs';
export const ARTIFACTS_PATH = 'artifacts';
export const DELIVERABLES_PATH = 'deliverables';
export const DELIVERABLES_FILES_PATH = 'files';
export const DELIVERABLES_APPS_PATH = 'apps';
export const DELIVERABLES_DISTROS_PATH = 'distros';
export const DELIVERABLES_CM4G_PATH = 'cm4g';
export const DELIVERABLES_BUNDLES_PATH = 'bundles';
export const EVENTS_PATH = 'events';
export const LOG_REQUESTS_PATH = 'log-requests';
export const DEVICES_ASSIGNED_PATH = 'devices-assigned';
export const REFERENCES_PATH = 'references';
