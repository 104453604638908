import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { outcomes } from '~/features/updateEvents/constants/outcomes';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const UpdateEventOutcome = ({ value }) => {
    return (
        <OverlayTrigger placement='top' overlay={
            <Tooltip id='tooltip' className='top-right'>
                <FormattedMessage id={outcomes[value].tooltip} />
            </Tooltip>}>
            <span className={classNames('label label-condensed', outcomes[value].className)}>
                <FormattedMessage id={outcomes[value].label} />
            </span>
        </OverlayTrigger>
    );
};

export default UpdateEventOutcome;

UpdateEventOutcome.propTypes = {
    // props
    value: PropTypes.string,
};
