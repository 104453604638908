import noop from 'lodash/fp/noop';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { fetchControlDevice } from '~/features/devices/actions/controlDeviceActions';
import { downloadDeviceLogFile, fetchDeviceLogs } from '~/features/deviceLogs/actions/deviceLogsActions';
import { fetchDeviceAction } from '~/features/deviceLogs/actions/deviceActionActions';

import { controlDeviceSelector } from '~/features/devices/selectors/controlDeviceSelectors';
import { deviceLogsSelector } from '~/features/deviceLogs/selectors/deviceLogSelectors';
import {
    canCreateDeviceActionsSelector, canUpdateDeviceActionsSelector,
    deviceActionSelector
} from '~/features/deviceLogs/selectors/deviceActionSelectors';
import DeviceLogs from '~/features/deviceLogs/components/DeviceLogs';
import { showDeviceActionEditor } from '~/features/deviceLogs/actions/deviceActionEditorActions';
import { registerDataInterest, unregisterDataInterest } from '~/features/base/actions/ui/dataInterestActions';
import { triggerDataFetcher } from '~/features/base/actions/ui/dataFetcherActions';
import uid from '~/features/base/utils/uid';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import {isDiagLogFileManagerSelector} from "~/features/user/selectors/permissionSelectors";

/**
 * Container for the device logs
 */
export class DeviceLogsContainer extends PureComponent {

    constructor(props) {
        super(props);
        this.name = uid();
    }

    render() {
        const { controlDevice } = this.props;
        return (<>{controlDevice && <DeviceLogs {...this.props}/>}</>);
    }

    componentWillMount() {
        const { serialNumber, controlDevice } = this.props;
        if (!controlDevice) {
            this.props.fetchControlDevice({ serialNumber });
            return;
        }
        //remove this condition after cm4 and tbm3 logs services are integrated
        if (controlDevice.type !== CM4) {
            this.props.registerDataInterest(this.name, [
                fetchDeviceAction({ serialNumber, silent: true }),
                fetchDeviceLogs({ serialNumber }),
            ]);
        }
        this.props.triggerDataFetcher();
        this.interval = setInterval(() => {
            this.props.triggerDataFetcher();
        }, 60000);
    }

    componentWillUnmount() {
        this.props.unregisterDataInterest(this.name);
        clearInterval(this.interval);
    }
}

export const mapStateToProps = (state, ownProps) => {
    return {
        canCreateDeviceActions: canCreateDeviceActionsSelector(state),
        canEditDeviceActions: canUpdateDeviceActionsSelector(state),
        isDeviceLogsUser: isDiagLogFileManagerSelector(state),
        controlDevice: controlDeviceSelector(state, ownProps),
        deviceAction: deviceActionSelector(state, ownProps),
        deviceLog: deviceLogsSelector(state, ownProps),
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
        fetchControlDevice: (options) => {
            dispatch(fetchControlDevice(options));
        },
        fetchDeviceAction: (options) => {
            dispatch(fetchDeviceAction(options));
        },
        fetchDeviceLogs: (options) => {
            dispatch(fetchDeviceLogs(options));
        },
        showDeviceActionEditor: (deviceAction) => {
            dispatch(showDeviceActionEditor(deviceAction));
        },
        downloadDeviceLogFile: (serialNumber, logId) => {
            dispatch(downloadDeviceLogFile(serialNumber, logId));
        },
        registerDataInterest: (name, options) => {
            dispatch(registerDataInterest(name, options));
        },
        unregisterDataInterest: (name) => {
            dispatch(unregisterDataInterest(name));
        },
        triggerDataFetcher: () => {
            dispatch(triggerDataFetcher());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceLogsContainer);

DeviceLogsContainer.defaultProps = {
    // props
    canCreateDeviceActions: false,
    canEditDeviceActions: false,
    isDeviceLogsUser: false,
    serialNumber: '',
    // functions
    fetchControlDevice: noop,
    fetchDeviceAction: noop,
    fetchDeviceLogs: noop,
    showDeviceActionEditor: noop,
    downloadDeviceLogFile: noop,
    registerDataInterest: noop,
    unregisterDataInterest: noop,
    triggerDataFetcher: noop,
};

DeviceLogsContainer.propTypes = {
    // props
    canCreateDeviceActions: PropTypes.bool,
    canEditDeviceActions: PropTypes.bool,
    isDeviceLogsUser: PropTypes.bool,
    serialNumber: PropTypes.string,
    controlDevice: PropTypes.object,
    deviceAction: PropTypes.object,
    deviceLog: PropTypes.array,
    // functions
    fetchControlDevice: PropTypes.func,
    fetchDeviceAction: PropTypes.func,
    fetchDeviceLogs: PropTypes.func,
    showDeviceActionEditor: PropTypes.func,
    downloadDeviceLogFile: PropTypes.func,
    registerDataInterest: PropTypes.func,
    unregisterDataInterest: PropTypes.func,
    triggerDataFetcher: PropTypes.func,
};
