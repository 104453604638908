import queryString from 'query-string';
import { getFromLocalStorage, saveToLocalStorage } from './localStorage';

export const detectFeatureToggles = () => {

    const detectToggle = (transform, name) => {
        const [_, searchParams = ''] = window.location.hash.split('?');
        const params = queryString.parse(searchParams);

        const toggleValue = params[name];
        const value = toggleValue ? saveToLocalStorage(name, toggleValue) : getFromLocalStorage(name);

        return value && transform(value);
    };

    const BooleanToggle = value => value === 'true' || value === '1';

    return {
        enforcedEnv: detectToggle(String, 'ft_env'),
        enforcedLocale: detectToggle(String, 'ft_enforceLocale'),
        mockedToken: detectToggle(String, 'ft_mockToken'),
        isPreview: detectToggle(BooleanToggle, 'ft_isPreview'),
        showRolloutState: detectToggle(BooleanToggle, 'ft_showRolloutState'),
        tracing: detectToggle(BooleanToggle, 'ft_tracing'),
        skipRoleCheck: detectToggle(BooleanToggle, 'ft_skipRoleCheck'),
        reportsBilling: detectToggle(BooleanToggle, 'ft_reportsBilling'),
        fotaPoC: detectToggle(BooleanToggle, 'ft_fotaPoc'),
        useValidToken: detectToggle(BooleanToggle, 'ft_useValidToken'),
        showArtifacts: detectToggle(BooleanToggle, 'ft_showArtifacts'),
        showVehicles: detectToggle(BooleanToggle, 'ft_showVehicles'),
    };
};
