import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment/moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import {
    deviceActionEditorSelector,
    deviceActionSelector
} from '~/features/deviceLogs/selectors/deviceActionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import partition from 'lodash/fp/partition';
import { ALL } from '~/features/base/constants/serviceOptions';
import { createDeviceAction, updateDeviceAction } from '~/features/deviceLogs/actions/deviceActionActions';
import {
    changeDeviceActionEditor,
    showDeviceActionEditor
} from '~/features/deviceLogs/actions/deviceActionEditorActions';
import DeviceLogsAction from '~/features/deviceLogs/components/DeviceLogsAction';
import DateRangePickerOTA from '~/features/base/components/DateRangePickerOTA/DateRangePickerOTA';
import map from 'lodash/fp/map';

interface OtherDevicesLogRequestProps {
    serialNumber: string;
    canCreateDeviceActions: boolean;
    canEditDeviceActions: boolean;
}

const OtherDevicesLogRequest: React.FC<OtherDevicesLogRequestProps> = ({
    serialNumber,
    canCreateDeviceActions,
    canEditDeviceActions
}) => {

    const deviceAction = useSelector(state => deviceActionSelector(state, { serialNumber }));

    const getLog = () => {
        if (deviceAction?.allLogs.length > 0) {
            return deviceAction.allLogs[0];
        }
        return undefined;
    };
    const todayTimeRange = {
        startValue: moment().startOf('day'),
        endValue: moment(),
    };

    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm({
        defaultValues: {
            dateInput: {
                startValue: getLog()?.from || todayTimeRange.startValue,
                endValue: getLog()?.to || todayTimeRange.endValue,
            }
        },
        mode: 'onChange'
    });

    const model = useSelector(deviceActionEditorSelector);

    const dispatch = useDispatch();
    const buttonTitle = deviceAction ? <FormattedMessage id='intl-msg:editLogRequest'/> :
        <FormattedMessage id='intl-msg:logs:createLogRequestButton'/>
    ;

    const onSubmit = data => {
        const partitions = partition(log => log.serviceId === ALL, model.logs);
        const allLogs = partitions[0];
        const serviceLogs = partitions[1];

        let deviceActionPayload = {
            ...model,
            allLogs,
            serviceLogs,
        };

        if (!deviceAction) {
            deviceActionPayload.logRangeChangeOverwriteStart = data.dateInput.startValue;
            deviceActionPayload.logRangeChangeOverwriteEnd = data.dateInput.endValue;
            dispatch(createDeviceAction(deviceActionPayload));
        } else {

            dispatch(updateDeviceAction(deviceActionPayload));
        }
        reset();

    };

    const buttonClassName = { disabled: deviceAction ? !canEditDeviceActions : !canCreateDeviceActions };

    const getDateInputValue = (value) => {
        if (value) {
            return moment(value);
        }
        return undefined;
    };
    const updateDateInputModel = (startValue, endValue) => {
        const timeRangeOverwrite = {
            from: startValue,
            to: endValue,
        };
        const logs = map(logItem => ({ ...logItem, ...timeRangeOverwrite }), model.logs);
        dispatch(changeDeviceActionEditor({
            logs,
            logRangeChangeOverwriteStart: startValue,
            logRangeChangeOverwriteEnd: endValue,
            logRangeChangeOverwrite: true,
        }));
    };

    useEffect(() => {
        if (deviceAction) {
            dispatch(showDeviceActionEditor({
                isNew: false,
                deviceAction,
            }));
            reset({
                dateInput: {
                    startValue: getLog()?.from,
                    endValue: getLog()?.to
                }
            });
        } else {
            dispatch(showDeviceActionEditor({
                    isNew: true,
                    hwSerial: serialNumber,
                }
            ));
        }

    }, [deviceAction]);

    return (
        <ExpanderPanel open title={<span className={'h5'}><FormattedMessage id='intl-msg:createLogRequest'/></span>}
                       bsStyle='blank'>

            <DeviceLogsAction deviceAction={deviceAction}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:overWriteDate'/>
                </label>
                <div
                    className={'margin-top-25 display-flex flex-row justify-content-between'}>
                    <Controller
                        control={control}
                        name='dateInput'
                        rules={{ required: true }}
                        render={({ field }) => <DateRangePickerOTA
                            hasError={!!errors.dateInput}
                            initialStartValue={getDateInputValue(field.value?.startValue)}
                            initialEndValue={getDateInputValue(field.value?.endValue)}
                            onRangeChange={(startValue, endValue) => {
                                field.onChange({ startValue, endValue });
                                updateDateInputModel(startValue, endValue);
                            }}/>}
                    />

                    <button type={'submit'} className={classNames('btn btn-primary', buttonClassName)} onClick={() => {
                    }}>
                        {buttonTitle}
                    </button>
                </div>

            </form>
        </ExpanderPanel>
    );
};

export default OtherDevicesLogRequest;
