import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

// Routes
import {
    DELIVERABLES_MANAGEMENT_PATH,
    ARTIFACTS_MANAGEMENT_PATH,
    DEVICES_PATH,
    FOTA_L3_PATH,
    GROUPS_PATH,
    OVERVIEW_PATH,
    REPORTS_BILLING_PATH,
    WALKTHROUGH_PATH,
    OLD_VEHICLES_PATH, VEHICLES_PATH,
} from '~/features/base/constants/routes';

import ApplicationContentWrapper from '~/features/base/components/ApplicationContentWrapper';
import SubModuleContainer from '~/features/base/components/SubModuleContainer';

// Primary routes
import OverviewRoute from '~/features/overview/components/OverviewRoute';
import DevicesRoute from '~/features/devices/components/DevicesRoute';
import GroupsRoute from '~/features/groups/components/GroupsRoute';

import ArtifactsRoute from '~/features/artifacts/components/ArtifactsRoute';
import DeliverablesRouteNeo from '~/features/deliverables/components/DeliverablesRoute';
import WalkthroughContainer from '~/features/walkthrough/WalkthroughContainer';
import { sendGoogleAnalyticsPageView } from '~/index';
import ReportsBillingRoute from '~/features/reportsbilling/components/ReportsBillingRoute';
import FotaL3Route from '~/features/fotaL3/components/FotaL3Route';
import OldVehiclesRoute from '~/features/oldVehicles/components/OldVehiclesRoute';
import VehiclesRoute from '~/features/vehicles/components/VehiclesRoute';

export const getRoutes = ({ match }) => {
    // send Google Analytics Path
    sendGoogleAnalyticsPageView(match.path);
    return (
        <ApplicationContentWrapper>
            <SubModuleContainer>
                <Switch>
                    <Route path={`${match.path}${OVERVIEW_PATH}`} component={OverviewRoute}/>
                    <Route path={`${match.path}${DEVICES_PATH}`} component={DevicesRoute}/>
                    <Route path={`${match.path}${OLD_VEHICLES_PATH}`} component={OldVehiclesRoute}/>
                    <Route path={`${match.path}${VEHICLES_PATH}`} component={VehiclesRoute}/>
                    <Route path={`${match.path}${GROUPS_PATH}`} component={GroupsRoute}/>
                    <Route path={`${match.path}${ARTIFACTS_MANAGEMENT_PATH}`} component={ArtifactsRoute}/>
                    <Route path={`${match.path}${DELIVERABLES_MANAGEMENT_PATH}`} component={DeliverablesRouteNeo}/>
                    <Route path={`${match.path}${WALKTHROUGH_PATH}`} component={WalkthroughContainer}/>
                    <Route path={`${match.path}${REPORTS_BILLING_PATH}`} component={ReportsBillingRoute}/>
                    <Route path={`${match.path}${FOTA_L3_PATH}`} component={FotaL3Route}/>
                    <Redirect to={`${match.url}${OVERVIEW_PATH}`}/>
                </Switch>
            </SubModuleContainer>
        </ApplicationContentWrapper>
    );
};

export const routes = withRouter(getRoutes);

export default routes;
