import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useFetchSystemsQuery, useRequestLogsMutation } from '~/api/logs/LoggingService.api';
import { IncomingLogsRequestDto } from '~/api/models/IncomingLogsRequestDto';
import { CM4 } from '~/features/devices/constants/deviceTypes';
import Notification from '@rio-cloud/rio-uikit/Notification';
import DateRangePickerOTA from '~/features/base/components/DateRangePickerOTA/DateRangePickerOTA';
import MultiSelectOTA from '~/features/base/components/MultiSelectOTA';
import moment from 'moment/moment';

interface CM4LogRequestProps {
    serialNumber: string;
    isDeviceLogsUser: boolean;
}

interface CM4LogRequestForm {
    requestedSystem: string[];
    dateInput: { startValue: moment.Moment, endValue: moment.Moment };
}

const CM4LogRequest: React.FC<CM4LogRequestProps> = ({ serialNumber, isDeviceLogsUser }) => {

    const { data: systems } = useFetchSystemsQuery(CM4);

    const [requestLogs] = useRequestLogsMutation();

    const todayTimeRange = {
        startValue: moment().startOf('day'),
        endValue: moment(),
    };
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
        setValue,
    } = useForm<CM4LogRequestForm>({
        defaultValues: {
            dateInput: { startValue: todayTimeRange.startValue, endValue: todayTimeRange.endValue }
        }, mode: 'onChange'
    });

    const handleLogRequest = async(requestParams: IncomingLogsRequestDto) => {

        await requestLogs(requestParams)
            .unwrap()
            .then(() => {
                Notification.success(<FormattedMessage
                    id='intl-msg:logs:logRequestWithSuccess'/>);
                reset({
                    dateInput: { startValue: todayTimeRange.startValue, endValue: todayTimeRange.endValue },
                    requestedSystem: systems?.map(system => system.id)
                });
            })
            .catch((error) => {
                console.error(error);
                Notification.error(<FormattedMessage id='intl-msg:logs:logRequestWithoutSuccess'/>);
            });

    };

    const onSubmit = async data => {

        const requestParams: IncomingLogsRequestDto = {
            deviceType: CM4,
            serialNumber: serialNumber,
            systems: data.requestedSystem,
            period: {
                minValue: data.dateInput.startValue,
                maxValue: data.dateInput.endValue
            }

        };
        await handleLogRequest(requestParams);
    };

    const buttonClassName = { disabled: !isDeviceLogsUser };

    useEffect(() => {
        if (systems) {
            setValue('requestedSystem', systems?.map(system => system.id));
        }
    }, [systems]);

    return (
        <ExpanderPanel open
                       title={<span className={'h5'}><FormattedMessage id='intl-msg:createLogRequest'/></span>}
                       bsStyle='blank'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label className='control-label'>
                    <FormattedMessage id='intl-msg:logs:systemLogging'/>
                </label>
                <Controller
                    control={control}
                    name='requestedSystem'
                    rules={{ required: true }}
                    render={({ field: { value, onChange }, }) => {
                        return (<MultiSelectOTA
                            hasError={!!errors.requestedSystem}
                            placeholder={<FormattedMessage id='intl-msg:logs:loggingSystemSelectionPlaceholder'/>}
                            options={structuredClone(systems)}
                            value={value}
                            onChange={onChange}
                        />);
                    }
                    }
                />

                <label className='margin-top-20 control-label'>
                    <FormattedMessage id='intl-msg:overWriteDate'/>
                </label>
                <div className={'display-flex flex-row justify-content-between'}>
                    <Controller
                        control={control}
                        name='dateInput'
                        rules={{ required: true }}
                        render={({ field }) => <DateRangePickerOTA
                            hasError={!!errors.dateInput}
                            initialStartValue={field.value?.startValue}
                            initialEndValue={field.value?.endValue}
                            onRangeChange={(startValue, endValue) => {
                                field.onChange({ startValue, endValue });
                            }}/>}/>


                    <button type={'submit'} className={classNames('btn btn-primary', buttonClassName)}
                            onClick={() => {
                            }}>
                        <FormattedMessage id='intl-msg:logs:createLogRequestButton'/>
                    </button>
                </div>

            </form>
        </ExpanderPanel>
    );
};

export default CM4LogRequest;
