import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useFetchLogsQuery} from '~/api/logs/LoggingService.api';
import size from 'lodash/fp/size';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import ListTableTS from '~/features/base/components/table/ListTableTS';
import {cm4LogRequestColumnDescriptors} from '~/features/deviceLogs/components/logsTable/cm4ColumnDescriptors';
import ReloadButton from '~/features/base/components/ReloadButton';
import map from 'lodash/fp/map';
import {renderTableData} from '~/features/base/components/table/Utils';
import LogFilesTable from '~/features/deviceLogs/components/logsTable/LogFilesTable';
import NoData from '~/features/base/components/NoData';
import {TableLoader} from '~/features/base/components/TableLoader';
import {LogsSearchCriteriaDeviceTypeEnum} from '~/api/models/logs-search-criteria';
import LogFilesDownloadZipButton from "~/features/deviceLogs/components/logsTable/LogFilesDownloadZipButton";

interface CM4LogsTableProps {
    serialNumber: string;
    deviceType: LogsSearchCriteriaDeviceTypeEnum;
}

const PAGE_SIZE = 50;
const CM4LogsTable: React.FC<CM4LogsTableProps> = ({serialNumber, deviceType}) => {

    const [page, setPage] = useState<number>(0);
    const {data: logsInfo, isLoading} = useFetchLogsQuery({
        serialNumber: serialNumber,
        deviceType: deviceType,
        pageNumber: page,
        pageSize: PAGE_SIZE,
    }, {refetchOnMountOrArgChange: true});
    const [openExpanders, setOpenExpander] = useState<{ [key: string]: boolean }>({});

    const handleReloadClick = () => {
        setPage(0);
    };
    const handleLoadMore = () => {
        setPage(page + 1);
    };

    if (isLoading) {
        const allLabels = cm4LogRequestColumnDescriptors.map(column => column.label);
        return (
            <>
                <div className={'h5 margin-bottom-20'}>
                    <span className={'h5'}><FormattedMessage id='intl-msg:logs'/></span>
                </div>
                <TableLoader labels={allLabels} nLines={4}/>
            </>);
    }

    if (!isLoading && !size(logsInfo?.content)) {
        return (
            <>
                <div className={'h5 margin-bottom-20'}>
                    <span className={'h5'}><FormattedMessage id='intl-msg:logs'/></span>
                </div>
                <NotFoundState fullWidth
                               headline={<FormattedMessage id='intl-msg:nothingFound'/>}
                               message={<div className={'display-flex'} style={{ flexDirection: 'column' }}
                               >
                                   <div className={'margin-bottom-25'}><FormattedMessage
                                       id='intl-msg:noDeviceLogsAvailable'/></div>
                                   <ReloadButton onReloadClick={handleReloadClick}/>
                               </div>}
                />

            </>
        );
    }

    const renderShowFilesButton = (operationId, id) => {
        return (
            <>
                <button type='button' className='margin-left-5' onClick={
                    (event) => {

                        if (openExpanders[operationId] !== undefined) {
                            setOpenExpander({
                                [operationId]: !openExpanders[operationId]
                            });
                        } else {
                            setOpenExpander({
                                [operationId]: true
                            });
                        }
                    }}>
                    {
                        openExpanders[operationId] ?
                            <span className='rioglyph rioglyph-chevron-up text-size-10' aria-hidden='true'></span>
                            : <span className='rioglyph rioglyph-chevron-down text-size-10' aria-hidden='true'></span>
                    }
                </button>
                <LogFilesDownloadZipButton id={id}/>
            </>
        );
    };

    const renderCustomRow = (item) => {
        const showFilesButton = item.logs.length > 0 ? renderShowFilesButton(item.operationId, item.id) : <NoData/>;
        const tableData = map(columnDescriptor => renderTableData(item, columnDescriptor), cm4LogRequestColumnDescriptors);
        if (size(tableData)) {
            tableData[5] = <td className=''>
                <div className='padding-right-25'>
                    <div className='display-inline-block'>
                        {tableData[5]?.props?.children}
                    </div>
                    <div className='display-inline-block'>
                        {showFilesButton}
                    </div>
                </div>
            </td>;
        }
        const key = item['operationId'];
        const tableRows = [
            <tr className='tableRow' key={key} data-key={key}>
                {tableData}
            </tr>,
        ];

        openExpanders[item.operationId] ?
            tableRows.push(
                <tr key={key + '_'}>
                    <td style={{ borderTop: 0 }} colSpan={cm4LogRequestColumnDescriptors.length}>
                        {item?.logs && <LogFilesTable key={key + '__'} logsData={item.logs}/>}
                    </td>
                </tr>,
            ) : null;
        return tableRows;
    };

    const hasMoreLogs = () => {
        if (logsInfo?.totalElements && logsInfo?.content.length) {
            return logsInfo?.totalElements > logsInfo?.content.length;
        } else {
            return false;
        }
    };

    return (
        <div className={'display-flex flex-column'}>
            <div className={'display-flex justify-content-between'}>
                <div className={'h5 margin-bottom-20'}>
                    <span className={'h5'}><FormattedMessage id='intl-msg:logs'/></span>
                </div>
                <ReloadButton onReloadClick={handleReloadClick} className={'align-self-end margin-bottom-15'}/></div>
            <div>
                <ListTableTS
                    className='table-bordered   table-layout-fixed'
                    items={logsInfo?.content || []}
                    itemKey='operationId'
                    columnDescriptors={cm4LogRequestColumnDescriptors}
                    renderCustomTableRow={renderCustomRow}
                />
                <div className={'display-flex justify-content-center'}>
                    <button id='load-more-button'
                            disabled={!hasMoreLogs()}
                            className='btn btn-default'
                            onClick={handleLoadMore}>
                        <FormattedMessage id='intl-msg:loadMore'/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CM4LogsTable;
