import React, { useRef, useState } from 'react';
import SortDirection from '@rio-cloud/rio-uikit/lib/es/SortDirection';
import classNames from 'classnames';
import SelectionTableHeader from '~/features/base/components/table/SelectionTableHeader';
import map from 'lodash/fp/map';
import TableHeader from '~/features/base/components/table/TableHeader';
import includes from 'lodash/fp/includes';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { sortByProperty } from '@rio-cloud/rio-uikit/lib/es/SortUtils';
import { FormattedMessage } from 'react-intl';
import { ColumnDescriptorModel } from '~/features/base/models/ColumnDescriptorModel';
import { renderTableData } from '~/features/base/components/table/Utils';

interface ListTableProps {
    items: any[];
    className?: string;
    allowSorting?: boolean;
    sortBy?: string;
    sortDir?: string;
    allowSelection?: boolean;
    selectedItems?: any[];
    showHeader?: boolean;
    searchValue?: string;
    onRowClick?: Function;
    preventRowClickEventPropagation?: boolean;
    itemKey: string;
    columnDescriptors: ColumnDescriptorModel[];
    selectedAll?: boolean;
    highlightSelectedRow?: boolean;
    renderCustomTableRow?: Function;
}

// table-layout-fixed table-bordered
const tableClassName = 'table table-colloumn-overflow-hidden table-head-filled table-sticky';
export const ACTIVE_CLASS = 'active';
export const DATA_ATTRIBUTE = 'data-key';

const ListTableTS: React.FC<ListTableProps> = (props: ListTableProps) => {
    const {
        items,
        className,
        showHeader = true,
        searchValue = '',
        onRowClick,
        allowSorting = false,
        allowSelection = false,
        itemKey = 'id',
        columnDescriptors = [],
        preventRowClickEventPropagation = true,
        selectedAll = false,
        selectedItems = [],
        highlightSelectedRow = false,
        renderCustomTableRow
    } = props;

    const [sortBy, setSortBy] = useState<string>(props.sortBy || '');
    const [sortDir, setSortDir] = useState<string>(props.sortDir || SortDirection.ASCENDING);
    const [activeRowId, setActiveRowId] = useState<string>('');
    const tableRef = useRef<HTMLTableElement>(null);

    const getSortDir = (sortBy, previousSortBy) => {
        if (sortBy === previousSortBy) {
            return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
        }
        return SortDirection.ASCENDING;
    };

    const handleSortChange = (event) => {
        if (allowSorting) {
            const sortBy = event.currentTarget.getAttribute('data-sortby');
            setSortBy(sortBy);
            setSortDir(getSortDir(sortBy, sortBy));
        }
    };

    const renderTableHead = (columnDescriptors, sortBy, sortDir) => {
        const selectionTableHeader = <SelectionTableHeader allowSelection={allowSelection}/>;
        const tableHeaders = map((columnDescriptor) => {
            if (columnDescriptor.hidden) {
                return;
            }
            return <TableHeader key={columnDescriptor.id} columnDescriptor={columnDescriptor} sortBy={sortBy}
                                sortDir={sortDir}
                                handleSortChange={handleSortChange}/>;
        }, columnDescriptors);
        return (
            <thead className='table-head'>
            <tr key={'tableHeadRow'}>
                {selectionTableHeader}
                {tableHeaders}
            </tr>
            </thead>
        );
    };

    const renderTableBody = (items) => {
        const tableRows = map(renderTableRow, items);
        return (
            <tbody>
            {tableRows}
            </tbody>
        );
    };

    const renderTableRow = (item) => {
        if (!!renderCustomTableRow) {
            return renderCustomTableRow(item);
        } else {
            if (!item) {
                return;
            }
            const selectionTableData = renderSelectionTableData(item);
            const tableData = map(columnDescriptor => renderTableData(item, columnDescriptor), columnDescriptors);
            const key = item[itemKey];
            return (
                <tr key={key} className={'tableRow'} data-key={key} onClick={onRowClicked}>
                    {selectionTableData}
                    {tableData}
                </tr>
            );
        }
    };

    const renderSelectionTableData = (item) => {
        const selected = selectedAll || includes(item[itemKey], selectedItems);
        if (allowSelection) {
            return (
                <td key={`td-${itemKey}`} className='selection'>
                    <Checkbox checked={selected}/>
                </td>
            );
        }
    };

    const onRowClicked = (event) => {
        if (onRowClick) {
            if (preventRowClickEventPropagation) {
                event.preventDefault();
                event.stopPropagation();
            }

            const rowId = event.currentTarget.getAttribute(DATA_ATTRIBUTE);

            setActiveRowId(activeRowId === rowId ? '' : rowId);

            const rows = Array.prototype.slice.call(tableRef?.current?.rows);

            if (highlightSelectedRow) {
                highlightRow(rows, rowId);
                removeHighlightFromRow(rows, activeRowId);
            }
            return onRowClick(rowId, activeRowId !== rowId);
        }
    };

    const getRowByDataAttribute = (rows: HTMLTableRowElement[], value = '', attribute = 'data-key') =>
        rows.find((row) => {
            const dataAttribute = row.attributes[attribute];
            if (dataAttribute) {
                return dataAttribute.value === value;
            }
            return false;
        });

    const highlightRow = (rows, activeRowId) => {
        const row = getRowByDataAttribute(rows, activeRowId);
        if (row) {
            row.classList.add(ACTIVE_CLASS);
        }
    };

    const removeHighlightFromRow = (rows, lastActiveRowId) => {
        const row = getRowByDataAttribute(rows, lastActiveRowId);
        if (row) {
            row.classList.remove(ACTIVE_CLASS);
        }
    };

    // in case a search value is given, filter the data accordingly
    const searchResult = !searchValue ? items : items.filter(row => (
        columnDescriptors.some(col => {
            try {
                const rowColumnId = row[col.id];
                return rowColumnId.toString().toLowerCase().includes(searchValue.toString().toLowerCase());
            } catch (e) {
                return false;
            }
        })
    ));

    if (!items) {
        return <FormattedMessage id='intl-msg:notFound'/>;
    }

    // Sort rows according to the sortBy and sortDir settings
    const rows = sortBy ? sortByProperty(searchResult, sortBy, sortDir) : searchResult;

    const tableHead = renderTableHead(columnDescriptors, sortBy, sortDir);
    const tableBody = renderTableBody(rows);
    let highlight = '';
    if (highlightSelectedRow) {
        highlight = 'table-hover';
    }

    return (
        <table ref={tableRef} className={classNames(tableClassName, className, highlight)}>
            {showHeader && tableHead}
            {tableBody}
        </table>

    );
};

export default ListTableTS;
