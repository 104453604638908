import map from 'lodash/fp/map';
import split from 'lodash/fp/split';

export const VERSION_ZERO = '0.0.0';

export const toShortSemanticVersion = (version) => {
    if (!version) {
        return 'unknown';
    }
    return `${version.major}.${version.minor}.${version.patch}`;
};

export const toSemanticVersion = (shortVersion) => {
    if (!shortVersion) {
        return 'unknown';
    }
    const parts = split('.', shortVersion);
    return {
        major: parseInt(parts[0], 10),
        minor: parseInt(parts[1], 10),
        patch: parseInt(parts[2], 10),
    };
};

export const isShortVersion = (version) => {
    const regex = /^\d+\.\d+\.\d+$/;
    return regex.test(version);
};

export const isSemanticVersionZero = (version) => {
    if (!version) {
        return 'unknown';
    }
    return VERSION_ZERO === `${version.major}.${version.minor}.${version.patch}`;
};

export const toShortDistroVersion = (distro) => {
    if (!distro) {
        return 'unknown';
    }
    return `${distro.year}.${distro.week}.${distro.number}`;
};

export const toShortVersion = (version) => {
    if (!version) {
        return 'unknown';
    }
    if (version.major) {
        return toShortSemanticVersion(version);
    }
    return toShortDistroVersion(version);

};

export const toDistroVersion = (shortDistro) => {
    if (!shortDistro) {
        return 'unknown';
    }
    const parts = split('.', shortDistro);
    return {
        year: parseInt(parts[0], 10),
        week: parseInt(parts[1], 10),
        number: parseInt(parts[2], 10),
    };
};

export const toShortSemanticVersions = versions => map(toShortSemanticVersion, versions);
export const toSemanticVersions = shortVersions => map(toSemanticVersion, shortVersions);

export const toShortDistroVersions = distros => map(toShortDistroVersion, distros);
export const toDistroVersions = shortDistros => map(toDistroVersion, shortDistros);
